<template>
  <div>
    <base-header
      class="header pb-8 pt-5 pt-lg-8 d-flex align-items-center"
      style="
        min-height: 200px;
        background-image: url(img/theme/profile-cover.jpg);
        background-size: cover;
        background-position: center top;
      "
    >
      <!-- Mask -->
      <span class="mask bg-gradient-blue opacity-8"></span>
      <!-- Header container -->
      <div class="container-fluid d-flex align-items-center">
        <div class="row">
          <div class="col-12">
            <h1 class="display-2 text-white">
              {{ $store.state.company.companyName }}
            </h1>
            <!--            <p class="text-white mt-0 mb-5">-->
            <!--              This is your profile page. You can see the progress you've made-->
            <!--              with your work and manage your projects or assigned tasks-->
            <!--            </p>-->
            <!--            <a href="#!" class="btn btn-info">Edit profile</a>-->
          </div>
        </div>
      </div>
    </base-header>

    <div class="container-fluid mt--7">
      <div class="row mb-5">
        <div class="col-xl-4 order-xl-2 mb-5 mb-xl-0">
          <div class="card shadow border-0">
            <div
              id="map"
              class="map-canvas"
              data-lat="40.748817"
              data-lng="-73.985428"
              style="height: 600px"
            ></div>
          </div>
        </div>

        <div class="col-xl-8 order-xl-1">
          <card shadow type="secondary">
            <template v-slot:header>
              <div class="bg-white border-0">
                <div class="row align-items-center">
                  <div class="col-8">
                    <h3 class="mb-0">Company Details</h3>
                  </div>
                  <!--                  <div class="col-4 text-right">-->
                  <!--                    <a href="#!" class="btn btn-sm btn-primary">Settings</a>-->
                  <!--                  </div>-->
                </div>
              </div>
            </template>

            <form>
              <h6 class="heading-small text-muted mb-4">Company Details</h6>
              <div class="pl-lg-4">
                <div class="row">
                  <div class="col-lg-6">
                    <base-input
                      disabled
                      alternative=""
                      label="Name"
                      placeholder="Name"
                      input-classes="form-control-alternative"
                      v-model="model.companyName"
                    />
                  </div>
                  <div class="col-lg-6">
                    <base-input
                      disabled
                      alternative=""
                      label="Status"
                      placeholder="Status"
                      input-classes="form-control-alternative"
                      v-model="model.status"
                    />
                  </div>
                </div>
                <div class="row">
                  <div class="col-lg-6">
                    <base-input
                      disabled
                      alternative=""
                      label="Contact Phone"
                      placeholder="Contact Phone"
                      input-classes="form-control-alternative"
                      v-model="model.contactPhone"
                    />
                  </div>
                  <div class="col-lg-12">
                    <label class="form-control-label">Company Types</label>
                    <div class="row col-lg-12 mb-4">
                      <badge
                        :key="type"
                        v-for="type in model.companyTypes"
                        pill
                        type="info"
                        class="m-1"
                        >{{ type }}</badge
                      >
                    </div>
                  </div>
                  <div class="col-lg-12">
                    <base-input alternative="" label="Company Description">
                      <textarea
                        disabled
                        rows="4"
                        class="form-control form-control-alternative"
                        placeholder="A few words about the company ..."
                        v-model="model.description"
                      >
                      </textarea>
                    </base-input>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-6">
                    <base-input
                      disabled
                      alternative=""
                      label="Address"
                      placeholder="Home Address"
                      input-classes="form-control-alternative"
                      v-model="model.location.street"
                    />
                  </div>
                  <div class="col-md-6">
                    <base-input
                      disabled
                      alternative=""
                      label="Address"
                      placeholder="Home Address 2"
                      input-classes="form-control-alternative"
                      v-model="model.location.street2"
                    />
                  </div>
                </div>
                <div class="row">
                  <div class="col-lg-4">
                    <base-input
                      disabled
                      alternative=""
                      label="City"
                      placeholder="City"
                      input-classes="form-control-alternative"
                      v-model="model.location.city"
                    />
                  </div>
                  <div class="col-lg-4">
                    <base-input
                      disabled="true"
                      alternative=""
                      label="Country"
                      placeholder="Country"
                      input-classes="form-control-alternative"
                      v-model="model.location.country"
                    />
                  </div>
                  <div class="col-lg-4">
                    <base-input
                      disabled="disabled"
                      alternative=""
                      label="Postal code"
                      placeholder="Postal code"
                      input-classes="form-control-alternative"
                      v-model="model.location.postalCode"
                    />
                  </div>
                </div>
              </div>
            </form>
          </card>
          <br />
          <card shadow type="secondary" class="mb-8">
            <template v-slot:header>
              <div class="bg-white border-0">
                <div class="row align-items-center">
                  <div class="col-8">
                    <h3 class="mb-0">Booking Settings</h3>
                  </div>
                  <!--                  <div class="col-4 text-right">-->
                  <!--                    <a href="#!" class="btn btn-sm btn-primary">Settings</a>-->
                  <!--                  </div>-->
                </div>
              </div>
            </template>

            <form>
              <!-- Address -->
              <h6 class="heading-small text-muted mb-4">Booking Details</h6>
              <div class="pl-lg-4">
                <div class="row">
                  <div class="col-lg-6">
                    <base-input
                      disabled
                      alternative=""
                      label="Service Location"
                      placeholder="Service Location"
                      input-classes="form-control-alternative"
                      v-model="bookingSettings.serviceLocation"
                    />
                  </div>
                  <div class="col-lg-6">
                    <base-input
                      disabled
                      alternative=""
                      label="Appointment Slot"
                      placeholder="Appointment Slot"
                      input-classes="form-control-alternative"
                      v-model="bookingSettings.appointmentSlot"
                    />
                  </div>
                </div>
                <div class="row">
                  <div class="col-lg-6">
                    <base-switch
                      disabled
                      label="Enable SMS Notifications"
                      v-model="bookingSettings.smsNotificationsEnabled"
                      :value="bookingSettings.smsNotificationsEnabled"
                    ></base-switch>
                  </div>
                </div>
              </div>
              <hr class="my-4" />
              <template
                v-if="
                  ['BUSINESS_AND_MOBILE', 'BUSINESS'].includes(
                    bookingSettings.serviceLocation
                  )
                "
              >
                <h6 class="heading-small text-muted mb-4">Business Services</h6>
                <div class="pl-lg-4">
                  <div class="row">
                    <div class="col-lg-6">
                      <base-switch
                        disabled="true"
                        label="Auto Approve Appointments"
                        v-model="bookingSettings.autoApprove"
                        :value="bookingSettings.autoApprove"
                      ></base-switch>
                    </div>
                    <div class="col-lg-6">
                      <base-input
                        disabled
                        alternative=""
                        label="Booking for Future"
                        placeholder="Booking for Future"
                        input-classes="form-control-alternative"
                        v-model="bookingSettings.futureBooking"
                      />
                    </div>
                    <div class="col-lg-6">
                      <base-input
                        disabled
                        alternative=""
                        label="Booking in Advance Future"
                        placeholder="Booking in Advance"
                        input-classes="form-control-alternative"
                        v-model="bookingSettings.bookingInAdvance"
                      />
                    </div>
                    <div class="col-lg-6">
                      <base-input
                        disabled
                        alternative=""
                        label="Cancellation Policy"
                        placeholder="Cancellation Policy"
                        input-classes="form-control-alternative"
                        v-model="bookingSettings.cancellationInAdvance"
                      />
                    </div>
                  </div>
                </div>
              </template>
              <hr class="my-4" />
              <template
                v-if="
                  ['BUSINESS_AND_MOBILE', 'MOBILE'].includes(
                    bookingSettings.serviceLocation
                  )
                "
              >
                <h6 class="heading-small text-muted mb-4">Remote Services</h6>
                <div class="pl-lg-4">
                  <div class="row">
                    <div class="col-lg-6">
                      <base-switch
                        disabled="true"
                        label="Auto Approve Remote Appointments"
                        v-model="bookingSettings.mobileAutoApprove"
                        :value="bookingSettings.mobileAutoApprove"
                      ></base-switch>
                    </div>
                    <div class="col-lg-6">
                      <base-input
                        disabled
                        alternative=""
                        label="Booking for Future"
                        placeholder="Remote Booking for Future"
                        input-classes="form-control-alternative"
                        v-model="bookingSettings.mobileFutureBooking"
                      />
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-lg-6">
                      <base-input
                        disabled
                        alternative=""
                        label="Booking in Advance Future"
                        placeholder="Remote Booking in Advance"
                        input-classes="form-control-alternative"
                        v-model="bookingSettings.mobileBookingInAdvance"
                      />
                    </div>
                    <div class="col-lg-6">
                      <base-input
                        disabled
                        alternative=""
                        label="Cancellation Policy"
                        placeholder="Remote Cancellation Policy"
                        input-classes="form-control-alternative"
                        v-model="bookingSettings.mobileCancellationInAdvance"
                      />
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-lg-6">
                      <base-input
                        disabled
                        alternative=""
                        label="Travel Time"
                        placeholder="Travel Time"
                        input-classes="form-control-alternative"
                        v-model="bookingSettings.travelTime"
                      />
                    </div>
                    <div class="col-lg-6">
                      <base-input
                        disabled
                        alternative=""
                        label="Travel Fee"
                        placeholder="Travel Fee"
                        input-classes="form-control-alternative"
                        v-model="bookingSettings.travelFee"
                      />
                    </div>
                  </div>
                </div>
              </template>

              <!-- Description -->
            </form>
          </card>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { Loader } from "google-maps";
import { store } from "../store";
const loader = new Loader();
export default {
  name: "user-profile",
  data() {
    return {
      nav: null,
      bookingSettings: {
        appointmentSlot: null,
        autoApprove: null,
        bookingInAdvance: null,
        cancellationInAdvance: null,
        futureBooking: null,
        loyaltyPercentage: null,
        loyaltyProgramEnable: null,
        mobileAutoApprove: null,
        mobileBookingInAdvance: null,
        mobileCancellationInAdvance: null,
        mobileFutureBooking: null,
        serviceLocation: "",
        smsNotificationsEnabled: null,
        travelFee: null,
        travelTime: null,
      },
      model: {
        description: "",
        createdAt: { seconds: 1588666432, nanoseconds: 992000000 },
        serviceLocation: "BUSINESS_AND_MOBILE",
        serviceGender: { UNISEX: true, MEN: true, WOMEN: true },
        gallery: [
          {
            url: "https://storage.googleapis.com/appoint-me-233515.appspot.com/companies/50q3nQ6HyYYrIgZLGQb5/gallery/1628920795.320955.jpg?GoogleAccessId=firebase-adminsdk-bdm4l%40appoint-me-233515.iam.gserviceaccount.com&Expires=3786912000&Signature=g6DNwQoY4h9Cg0Dlyx7Dss0T7kS4LJd7NzHA7avPqho%2B7MP4TOa8i4o7O5oLUH1vMuvWbLNhU2kfAkfHMcQFHmRigiCuQO28K4Or5ihi055cAn0h435t6eEsFWI4V%2BLGT6eSLqYZ9l024GcV2cVxlcrftqXpDxxJuxBgRRUy2PZylGgv3Dsqa5l9qPOo5lEsmIx%2Fo3GEwjrjSSiv03yUObfcXnuB%2B4acqlJJq3AED8A8EHfqw3Kp9MAphurIzY1mBjVMTznUa%2Bhsw%2FzNDUxrqiGllm4G5dzZcKHVc5%2FlW1vIbcnc24YwZU5t9rEfhdK3uaAf4hVb%2BaWZunE8wIcdPg%3D%3D",
            filename: "1628920795.320955.jpg",
          },
          {
            url: "https://storage.googleapis.com/appoint-me-233515.appspot.com/companies/50q3nQ6HyYYrIgZLGQb5/gallery/1607788182.83505.jpg?GoogleAccessId=firebase-adminsdk-bdm4l%40appoint-me-233515.iam.gserviceaccount.com&Expires=3786912000&Signature=go4kL1CaCUDgn2bQOd%2BcMANGPTlPNPwfSLhR8XupD%2B9%2F6av81BflzNLh1qR1adVE%2B105FGPvClR80DQMWlNXIZQYqpNBfydqURDL7V%2Bw38ZeSrl%2F5O019jkFRM5hB3vAyz7Wn5uKH%2Fz5BmJfS0YrueGEIrJ0TRlltU3KmbDrPXf5%2F0Ai8oNelNzJWEzzKPPCCvQ%2FGD5tJ8mFD9518CqaSNg3MADhL75zlCs1kLC8nWSiqVoVZKG9yxV6FvtuCAsrD%2FpxRJsStCcMPb0ZUTaFTTlKNk1yp2khvTsbWQ8ShyY6rwoZUZQVDKDa%2BvEjVXBkJilPCBL%2Fj0rKC8Wb57VCIg%3D%3D",
            filename: "1607788182.83505.jpg",
          },
          {
            url: "https://storage.googleapis.com/appoint-me-233515.appspot.com/companies/50q3nQ6HyYYrIgZLGQb5/gallery/1633587322.89472.jpg?GoogleAccessId=firebase-adminsdk-bdm4l%40appoint-me-233515.iam.gserviceaccount.com&Expires=3786912000&Signature=SPfcJYt%2Fohy38fHueB0e3ATBtNe502JfP%2BW55pfV8HuKs195%2Fm1xZUUYe1Y5EJJRxYCCf3LPOLH5wRROj8y00%2Fqgl1qzn6BMG3IK7Jq0UxOr4RO05jTKwnm7J%2Byy1%2B%2FxA7c4QN7jYfpa4d1ymzzBZj%2FbapJjUzDZ0Z%2FDRLZUDQ75LDXRGuJBCxlvlR3%2BrBkStBU8nxZm4R1o6eZ1mJBjKJGQLPtW80hVi6XDqwkYEMTYzytjyQcr4NdDjuOXj%2BYU0HPC4MlDXy07%2FK8Q9vuS2Zs3mBCstl16eVFsZZQYv6wSYrhEx3IKC34JAk9ZWSzIc5aa01Hoj0EHABhv8V97DQ%3D%3D",
            filename: "1633587322.89472.jpg",
          },
        ],
        autoApprove: true,
        updatedAt: { seconds: 1630071449, nanoseconds: 847000000 },
        futureBooking: 129600,
        companyName: "Oni Geo2 Company",
        status: "",
        companyTypes: [
          "HAIR_SALON",
          "MAKEUP_STUDIO",
          "BEAUTY_SALON",
          "TATTOO_&_PIERCING_STUDIO",
          "MASSAGE_&_SPAG_STUDIO",
        ],
        contactPhone: "+35796375541",
        contactEmail: "",
        travelTime: 15,
        openingHours: [
          "MONDAY-10:00-13:00",
          "MONDAY-13:00-22:00",
          "WEDNESDAY-08:00-18:00",
          "THURSDAY-08:00-18:00",
          "FRIDAY-08:00-19:00",
          "SUNDAY-09:00-19:00",
          "SATURDAY-09:00-17:00",
          "TUESDAY-07:00-20:00",
          "FRIDAY-21:00-22:00",
          "TUESDAY-21:00-23:00",
        ],
        mobileAutoApprove: true,
        appointmentSlot: 20,
        website: "www.google.com",
        travelFee: 5,
        instagram: "www.Instagram.com/onisiforosg",
        facebook: "www.facebook.com/onisigoros",
        updatedBy: "G2IIP5E63AZZw20o1gatUGEcUql2",
        bookingInAdvance: 120,
        ownerId: "G2IIP5E63AZZw20o1gatUGEcUql2",
        location: {
          street: "",
          country: "",
          city: "",
          coords: { latitude: 0, longitude: 0 },
          postalCode: "",
          street2: "",
        },
      },
    };
  },
  mounted() {
    var self = this;
    this.getBookingSettings();
    this.mapCompany();

    loader.load().then(function (google) {
      // Regular Map
      const myLatlng = new google.maps.LatLng(
        self.model.location.coords.latitude,
        self.model.location.coords.longitude
      );
      const mapOptions = {
        zoom: 14,
        center: myLatlng,
        scrollwheel: false, // we disable de scroll over the map, it is a really annoing when you scroll through page
        disableDefaultUI: true, // a way to quickly hide all controls
        zoomControl: true,
        styles: [
          {
            featureType: "administrative",
            elementType: "labels.text.fill",
            stylers: [{ color: "#444444" }],
          },
          {
            featureType: "landscape",
            elementType: "all",
            stylers: [{ color: "#f2f2f2" }],
          },
          {
            featureType: "poi",
            elementType: "all",
            stylers: [{ visibility: "off" }],
          },
          {
            featureType: "road",
            elementType: "all",
            stylers: [{ saturation: -100 }, { lightness: 45 }],
          },
          {
            featureType: "road.highway",
            elementType: "all",
            stylers: [{ visibility: "simplified" }],
          },
          {
            featureType: "road.arterial",
            elementType: "labels.icon",
            stylers: [{ visibility: "off" }],
          },
          {
            featureType: "transit",
            elementType: "all",
            stylers: [{ visibility: "off" }],
          },
          {
            featureType: "water",
            elementType: "all",
            stylers: [{ color: "#5e72e4" }, { visibility: "on" }],
          },
        ],
      };
      const map = new google.maps.Map(
        document.getElementById("map"),
        mapOptions
      );
      const marker = new google.maps.Marker({
        position: myLatlng,
        title: "Regular Map!",
      });
      marker.setMap(map);
    });
  },
  methods: {
    async getBookingSettings() {
      let self = this;
      await store.dispatch("fetchBookingSettings").then((data) => {
        console.log("data", data);
        self.mapBookingSettings(data);
      });
    },
    mapBookingSettings(data) {
      // SETTINGS
      this.bookingSettings = data;
    },
    mapCompany() {
      let company = this.$store.state.company;
      console.log("mapCompany", company);
      this.model.location = company.location;
      this.model.companyName = company.companyName;
      this.model.companyTypes = company.companyTypes;
      this.model.status = company.status;
      this.model.contactPhone = company.contactPhone;
      this.model.description = company.description;
    },
  },
};
</script>
<style></style>
