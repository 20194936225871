<template>
  <div class="wrapper" :class="{ 'nav-open': $sidebar.showSidebar }">
    <side-bar
      :background-color="sidebarBackground"
      short-title="AppointMe"
      title="AppointMe"
    >
      <template v-slot:links>
        <sidebar-item
          v-if="['SYSTEM_ADMIN'].includes($store.state.user.claims.role)"
          :link="{
            name: 'Companies',
            icon: 'ni ni-building text-primary',
            path: '/companies',
          }"
        >
        </sidebar-item>
        <sidebar-item
          v-if="['SYSTEM_ADMIN'].includes($store.state.user.claims.role)"
          :link="{
            name: 'System Users',
            icon: 'fa fa-users text-primary',
            path: '/system-users',
          }"
        ></sidebar-item>
        <sidebar-item
          v-if="['SYSTEM_ADMIN'].includes($store.state.user.claims.role)"
          :link="{
            name: 'Manage Claims',
            icon: 'fa fa-box text-primary',
            path: '/manage-claims',
          }"
        ></sidebar-item>
        <sidebar-item
          v-if="['SYSTEM_ADMIN'].includes($store.state.user.claims.role)"
          :link="{
            name: 'Invoices',
            icon: 'fa fa-money-check-alt text-primary',
            path: '/system-invoices',
          }"
        ></sidebar-item>

        <sidebar-item
          v-if="['OWNER'].includes($store.state.user.claims.role)"
          :link="{
            name: 'Dashboard',
            icon: 'ni ni-tv-2 text-success',
            path: '/dashboard',
          }"
        >
        </sidebar-item>
        <!--Divider-->

        <sidebar-item
          v-if="['OWNER'].includes($store.state.user.claims.role)"
          :link="{
            name: 'Profile',
            icon: 'ni ni-single-02 text-success',
            path: '/profile',
          }"
        />
        <sidebar-item
          v-if="['OWNER'].includes($store.state.user.claims.role)"
          :link="{
            name: 'Invoices',
            icon: 'ni ni-money-coins text-success',
            path: '/invoices',
          }"
        />
        <sidebar-item
          v-if="['OWNER'].includes($store.state.user.claims.role)"
          :link="{
            name: 'Company',
            icon: 'ni ni-building text-success',
            path: '/company',
          }"
        />
        <sidebar-item
          v-if="['OWNER'].includes($store.state.user.claims.role)"
          :link="{
            name: 'Billing settings',
            icon: 'fa fa-money-check text-success',
            path: '/billing-settings',
          }"
        />
        <sidebar-item
          v-if="['OWNER'].includes($store.state.user.claims.role)"
          :link="{
            name: 'Revenue Reports',
            icon: 'fa fa-file-pdf text-success',
            path: '/revenue-reports',
          }"
        />
        <!--        <sidebar-item-->
        <!--          :link="{-->
        <!--            name: 'Clients',-->
        <!--            icon: 'ni ni-planet text-blue',-->
        <!--            path: '/icons',-->
        <!--          }"-->
        <!--        />-->
        <!--        <sidebar-item-->
        <!--          :link="{-->
        <!--            name: 'Working Hours',-->
        <!--            icon: 'ni ni-bullet-list-67 text-red',-->
        <!--            path: '/tables',-->
        <!--          }"-->
        <!--        />-->
      </template>
    </side-bar>
    <div class="main-content" :data="sidebarBackground">
      <dashboard-navbar></dashboard-navbar>

      <div @click="toggleSidebar">
        <!-- your content here -->
        <router-view></router-view>
        <content-footer v-if="!$route.meta.hideFooter"></content-footer>
      </div>
    </div>
  </div>
</template>
<script>
import DashboardNavbar from "./DashboardNavbar.vue";
import ContentFooter from "./ContentFooter.vue";

export default {
  components: {
    DashboardNavbar,
    ContentFooter,
  },
  data() {
    return {
      sidebarBackground: "vue", //vue|blue|orange|green|red|primary
    };
  },
  methods: {
    toggleSidebar() {
      if (this.$sidebar.showSidebar) {
        this.$sidebar.displaySidebar(false);
      }
    },
  },
};
</script>
<style lang="scss" scoped>

</style>
