<template>
  <nav
    class="navbar navbar-vertical fixed-left navbar-expand-md navbar-light bg-white"
    id="sidenav-main"
  >
    <div class="container-fluid">
      <!--Toggler-->
      <navbar-toggle-button @click="showSidebar">
        <span class="navbar-toggler-icon"></span>
      </navbar-toggle-button>
      <router-link class="navbar-brand" to="/">
        <!--        <img :src="logo" class="navbar-brand-img" alt="..." />-->
        <h1 class="text-white rounded shadow bg-gradient-green pl-2 pr-2" style="white-space: pre-wrap;">
          {{ $store.state.company.companyName ?? "AppointMe" }}
        </h1>
      </router-link>

      <slot name="mobile-right">
        <ul class="nav align-items-center d-md-none">
          <base-dropdown class="nav-item" position="right">
            <template v-slot:title>
              <a class="nav-link" href="#" role="button">
                <div class="media align-items-center">
                  <span class="avatar avatar-sm rounded-circle">
                    <i class="fa fa-user"></i>
                  </span>
                </div>
              </a>
            </template>

            <div class="dropdown-header noti-title">
              <h6 class="text-overflow m-0">Welcome!</h6>
            </div>
            <router-link to="/profile" class="dropdown-item">
              <i class="ni ni-single-02"></i>
              <span>My profile</span>
            </router-link>
            <router-link to="/profile" class="dropdown-item">
              <i class="ni ni-settings-gear-65"></i>
              <span>Settings</span>
            </router-link>
            <router-link to="/profile" class="dropdown-item">
              <i class="ni ni-calendar-grid-58"></i>
              <span>Activity</span>
            </router-link>
            <router-link to="/profile" class="dropdown-item">
              <i class="ni ni-support-16"></i>
              <span>Support</span>
            </router-link>
            <div class="dropdown-divider"></div>
            <a href="#!" class="dropdown-item">
              <i class="ni ni-user-run"></i>
              <span>Logout</span>
            </a>
          </base-dropdown>
        </ul>
      </slot>
      <slot></slot>
      <div
        v-show="$sidebar.showSidebar"
        class="navbar-collapse collapse show"
        id="sidenav-collapse-main"
      >
        <div class="navbar-collapse-header d-md-none">
          <div class="row">
            <div class="col-6 collapse-brand">
              <router-link to="/">
                <img :src="logo" />
              </router-link>
            </div>
            <div class="col-6 collapse-close">
              <navbar-toggle-button
                @click="closeSidebar"
              ></navbar-toggle-button>
            </div>
          </div>
        </div>

        <ul class="navbar-nav">
          <slot name="links"> </slot>
        </ul>
        <!--Divider-->
        <hr class="my-3" />
        <!--Heading-->
        <h6 class="navbar-heading text-muted">Help Center</h6>
        <!--Navigation-->
        <ul class="navbar-nav mb-md-3">
          <li class="nav-item">
            <router-link class="nav-link" to="/feature-ideas">
              <i class="ni ni-spaceship"></i> Feature Ideas
            </router-link>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="/faq">
              <i class="fas fa-question-circle"></i> Frequently asked questions
            </a>
          </li>
          <li class="nav-item">
            <router-link class="nav-link" to="/contact-us">
              <i class="fas fa-hands-helping"></i> Contact Us
            </router-link>
          </li>
          <!--          <li class="nav-item">-->
          <!--            <a-->
          <!--              class="nav-link"-->
          <!--              href="https://demos.creative-tim.com/vue-argon-dashboard/documentation/foundation/colors.html"-->
          <!--            >-->
          <!--              <i class="ni ni-palette"></i> Foundation-->
          <!--            </a>-->
          <!--          </li>-->
          <!--          <li class="nav-item">-->
          <!--            <a-->
          <!--              class="nav-link"-->
          <!--              href="https://demos.creative-tim.com/vue-argon-dashboard/documentation/components/alerts.html"-->
          <!--            >-->
          <!--              <i class="ni ni-ui-04"></i> Components-->
          <!--            </a>-->
          <!--          </li>-->
        </ul>
      </div>
      <span class="text-xs d-block font-weight-bold hideOn768" style="color: lightgrey">
        POWERED BY AppointMe
      </span>
      <span class="text-xs d-block font-weight-bold text-muted">
            v{{ version }}
      </span>
    </div>
  </nav>
</template>
<script>
import NavbarToggleButton from "@/components/NavbarToggleButton";
import packageData from "../../../package.json";

export default {
  name: "sidebar",
  components: {
    NavbarToggleButton,
  },
  data() {
    return {
      version: null
    }
  },
  props: {
    logo: {
      type: String,
      default: "/img/brand/amappointmePartner.png",
      description: "Sidebar app logo",
    },
    autoClose: {
      type: Boolean,
      default: true,
      description:
        "Whether sidebar should autoclose on mobile when clicking an item",
    },
  },
  provide() {
    return {
      autoClose: this.autoClose,
    };
  },
  methods: {
    closeSidebar() {
      this.$sidebar.displaySidebar(false);
    },
    showSidebar() {
      this.$sidebar.displaySidebar(true);
    },
  },
  mounted() {
    this.version = packageData.version
  },
  beforeUnmount() {
    if (this.$sidebar.showSidebar) {
      this.$sidebar.showSidebar = false;
    }
  },
};
</script>
