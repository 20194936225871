<template>
  <div>
    <base-header type="gradient-success" class="pb-6 pb-8 pt-5 pt-md-8">
      <!-- Card stats -->
    </base-header>

    <div class="container-fluid mt--7">
      <div class="col-xl-8 order-xl-1">
        <card shadow type="secondary">
          <template v-slot:header>
            <div class="bg-white border-0">
              <div class="row align-items-center">
                <div class="col-8">
                  <h3 class="mb-0">Billing Settings</h3>
                </div>
                <div class="col-4 text-right">
                  <base-button @click="fetchBillingSettings()" class="btn btn-sm btn-outline-success">Refresh</base-button>
                </div>
              </div>
            </div>
          </template>

          <form>
            <h4 class="text-muted mb-4">
              We need this settings only to handle your card payments <br>
              Please fill in your details correctly.
            </h4>
            <div class="">
              <div class="row">
                <div class="col-lg-6">
                  <base-input
                    alternative=""
                    label="Recipient name"
                    placeholder="Your name or company name"
                    input-classes="form-control-alternative"
                    v-model="model.recipientName"
                    :valid="!v$.model.recipientName.$invalid"
                    :error="v$.model.recipientName.$errors.map( err => { return err.$message }).join()"
                  />
                </div>
                <div class="col-lg-6">
                  <base-input
                    alternative=""
                    label="VAT number"
                    placeholder="VAT number"
                    input-classes="form-control-alternative"
                    v-model="model.vatNumber"
                  />
                </div>
              </div>

              <div class="row">
                <div class="col-md-6">
                  <base-input
                    alternative=""
                    label="Address"
                    placeholder="Street"
                    input-classes="form-control-alternative"
                    v-model="model.location.street"
                    :valid="!v$.model.location.street.$invalid"
                    :error="v$.model.location.street.$errors.map( err => { return err.$message }).join()"
                  />
                </div>
                <div class="col-md-6">
                  <base-input
                    alternative=""
                    label="Address 2"
                    placeholder="Street 2"
                    input-classes="form-control-alternative"
                    v-model="model.location.street2"
                  />
                </div>
              </div>
              <div class="row">
                <div class="col-lg-4">
                  <base-input
                    alternative=""
                    label="City"
                    placeholder="City"
                    input-classes="form-control-alternative"
                    v-model="model.location.city"
                    :valid="!v$.model.location.city.$invalid"
                    :error="v$.model.location.city.$errors.map( err => { return err.$message }).join()"
                  />
                </div>
                <div class="col-lg-4">
                  <base-input
                    alternative=""
                    label="Country"
                    placeholder="Country"
                    input-classes="form-control-alternative"
                    v-model="model.location.country"
                    :valid="!v$.model.location.country.$invalid"
                    :error="v$.model.location.country.$errors.map( err => { return err.$message }).join()"
                  />
                </div>
                <div class="col-lg-4">
                  <base-input
                    alternative=""
                    label="Postal code"
                    placeholder="Postal code"
                    input-classes="form-control-alternative"
                    v-model="model.location.postalCode"
                    :valid="!v$.model.location.postalCode.$invalid"
                    :error="v$.model.location.postalCode.$errors.map( err => { return err.$message }).join()"
                  />
                </div>
              </div>

            </div>
            <div class="text-right pt-2">
              <submit-button @clicked="submit" :alertMessage="errorMessage" :state="loadingProcess">
                Submit
              </submit-button>
              <!--              <base-button @click="logIn" type="primary" class="my-4">Sign in</base-button>-->
            </div>
          </form>
        </card>
      </div>
    </div>
  </div>
</template>
<script>
import FirebaseFunctionsHelper from "../plugins/firebaseFunctionsHelper";
import BaseButton from "../components/BaseButton";
import { store } from "../store";
import useVuelidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";

export default {
  name: "billing-settings",
  components: { BaseButton },
  setup: () => ({ v$: useVuelidate() }),
  validations() {
    return {
      model: {
        recipientName: {
          required,
        },
        location: {
          street: {
            required,
          },
          country: {
            required,
          },
          postalCode: {
            required,
          },
          city: {
            required,
          },
        },
      },
    };
  },
  data() {
    return {
      billingSettings: {},
      loadingProcess: "",
      errorMessage: "",
      model: {
        recipientName: "",
        vatNumber: "",
        location: {
          street: "",
          street2: "",
          country: "",
          postalCode: "",
          city: ""
        }
      },
    };
  },
  mounted() {
    this.fetchBillingSettings()
  },
  methods: {
    async fetchBillingSettings() {
      let self = this;
      await store.dispatch("fetchBillingSettings").then((data) => {
        if (data) {
          self.billingSettings = data;
          self.model = data;
        }
      });
    },
    async submit() {
      this.v$.$touch();

      if (this.v$.model.$invalid) {
        console.log(this.v$.$errors);
        return;
      }
      this.loadingProcess = "processing";

      const data = {
        recipientName: this.model.recipientName,
        vatNumber: this.model.vatNumber,
        street: this.model.location.street,
        street2: this.model.location.street2,
        country: this.model.location.country,
        postalCode: this.model.location.postalCode,
        city: this.model.location.city,
      }

      await FirebaseFunctionsHelper.callFunction("updateCompanyBillingSettings", data
      )
        .then(() => {
          this.loadingProcess = "done";
        })
        .catch((error) => {
          this.loadingProcess = "failed";
          this.errorMessage = error.message;
          console.error(error);
        });
    },
  }
};
</script>
<style></style>
