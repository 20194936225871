import { createApp } from "vue";
import App from "./App.vue";
import { store } from "./store";
import router from "./router";
import { KinesisContainer, KinesisElement } from "vue-kinesis";
import ArgonDashboard from "./plugins/argon-dashboard";
import "element-plus/lib/theme-chalk/index.css";
import { initializeApp } from "firebase/app";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import i18n from "./lang/lang.js";
import axios from "axios";
import VueAxios from "vue-axios";

const {
  initializeAppCheck,
  ReCaptchaV3Provider,
} = require("firebase/app-check");
import { getAnalytics } from "firebase/analytics";
// import "vue3-date-time-picker/dist/main.css";

export let firebaseConfig
if (process.env.NODE_ENV === "production") {
  firebaseConfig = require("../prodConfig.json");
} else {
  firebaseConfig = require("../devConfig.json");
}
export const stripePk = firebaseConfig.stripePk;
export const fireApp = initializeApp(firebaseConfig);
export const firestore = getFirestore();

let site_key = "";
if (firebaseConfig.projectId === "appoint-me-233515") {
  site_key = "6LeYhxIcAAAAAPsNwO_FyD65EBJ6BmUKjo-HN3hz";
} else {
  site_key = "6Lc5APQeAAAAAKIJzUtWse0CThbTX20DlgmUsD0I";
}

export const appCheck = initializeAppCheck(fireApp, {
  provider: new ReCaptchaV3Provider(site_key),
  // Optional argument. If true, the SDK automatically refreshes App Check
  // tokens as needed.
  isTokenAutoRefreshEnabled: false,
});

export const analytics = getAnalytics(fireApp);
export const auth = getAuth(fireApp);

onAuthStateChanged(auth, async (user) => {
  if (user == null && store.state.user.data !== null) {
    await store.dispatch("signOut");
    await router.push({ path: "login" });
  }
  // console.log("store.state.user.data", store.state.user.data);
  // console.log("store.state.user.claims ", store.state.user.claims);
  // console.log("user ", user);
  if (
    (store.state.user.data == null || store.state.user.claims == null) &&
    user !== null
  ) {
    await store.dispatch("fetchUser", user);
    // eslint-disable-next-line no-prototype-builtins
    if (
      store.state.user.claims !== null &&
      store.state.user.claims.role === "SYSTEM_ADMIN"
    ) {
      await router.push({ path: "companies" });
    } else if (
      store.state.user.claims !== null &&
      store.state.user.claims.role === "OWNER"
    ) {
      await router.push({ path: "dashboard" });
    }
  }
});

const appInstance = createApp(App);
appInstance.use(router);
appInstance.use(store);
appInstance.use(i18n);
appInstance.use(VueAxios, axios);
appInstance.component("kinesis-container", KinesisContainer);
appInstance.component("kinesis-element", KinesisElement);

router.beforeEach(async (to, from, next) => {
  let currentUser = await store.dispatch("isLoggedIn");
  let requiresAuth = to.matched.some((record) => record.meta.requiresAuth);
  let roleAccess = to.meta.role;
  // console.log(roleAccess);
  if (!isAllowed() && currentUser) {
    next("404");
    return;
  }

  if (to.name === "QuickBook") {
    next();
  }

  if (to.name === "login" && currentUser && isAllowed()) {
    next("dashboard");
  }

  if (to.name === "Admin Login" && currentUser && isAllowed()) {
    next("dashboard");
  }

  if ((requiresAuth && !currentUser) || !isAllowed()) next("login");
  else next();

  function isAllowed() {
    if (roleAccess == null || store.state.user.claims == null) {
      return true;
    }
    return store.state.user.claims.role === roleAccess;
  }
});
appInstance.config.devotools = true;
appInstance.use(ArgonDashboard);
appInstance.mount("#app");
