<template>
  <div>
    <base-header type="gradient-success" class="pb-6 pb-8 pt-5 pt-md-8">
      <!-- Card stats -->
    </base-header>

    <div class="container-fluid mt--7">
      <div class="row">
        <div class="col">
          <system-users-table title="System Users List"></system-users-table>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import SystemUsersTable from "../Tables/SystemUsersTable";
export default {
  name: "tables",
  components: {
    SystemUsersTable,
  },
};
</script>
<style></style>
