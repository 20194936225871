<template>
  <div>
    <base-header
      class="header pb-8 pt-5 pt-lg-8 d-flex align-items-center"
      style="
        min-height: 200px;
        background-image: url(img/theme/profile-cover.jpg);
        background-size: cover;
        background-position: center top;
      "
    >
      <!-- Mask -->
      <span class="mask bg-gradient-success opacity-8"></span>
      <!-- Header container -->
      <div class="container-fluid d-flex align-items-center">
        <div class="row">
          <div class="col-12">
            <!--            <h1 class="display-2 text-white">Invoice Id: {{model.id}}</h1>-->
            <!--            <p class="text-white mt-0 mb-5">-->
            <!--              This is your profile page. You can see the progress you've made-->
            <!--              with your work and manage your projects or assigned tasks-->
            <!--            </p>-->
            <!--            <a href="#!" class="btn btn-info">Edit profile</a>-->
          </div>
        </div>
      </div>
    </base-header>
    <div class="container-fluid mt--7">
      <div class="row mb-2">
        <div class="col-md-12 text-right">
          <div
            class="actions card bg-translucent-white p-2 mb-2 d-inline-block"
            style="width: 100%"
          >
            <base-button
              v-if="model.status === 'PENDING'"
              class="float-right"
              @click="
                showModal(
                  'Verify Invoice',
                  'Are you sure you want to verify this invoice?',
                  verifyInvoice
                )
              "
              type="success"
              >Verify</base-button
            >
            <label
              class="float-left m-2 text-black-50 text-uppercase font-weight-800"
              >Actions</label
            >
            <base-button
              v-if="model.status === 'PENDING'"
              class="float-right"
              @click="
                showModal(
                  'Reject Invoice',
                  'Are you sure you want to reject this invoice?',
                  rejectInvoice
                )
              "
              type="danger"
              >Reject</base-button
            >
            <base-button
              tag="a"
              :href="'/companies/' + model.companyId"
              class="float-right mr-2"
              type="info"
              >Go to Company</base-button
            >
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-xl-6 order-xl-1">
          <card shadow type="secondary">
            <template v-slot:header>
              <div class="bg-white border-0">
                <div class="row align-items-center">
                  <div class="col-6">
                    <h3 class="mb-0">Invoice information</h3>
                  </div>
                  <div class="col-6 text-right">
                    <label class="text-sm text-black-50">Invoice Type:</label>
                    <label>{{ model.type }}</label>
                  </div>
                </div>
              </div>
            </template>

            <form>
              <h6 class="heading-small text-muted mb-4">
                Invoice ID: {{ model.uid }}
              </h6>
              <div class="pl-lg-4">
                <div class="row">
                  <div class="col-lg-6">
                    <display-label
                      label="Selected Packages"
                      :value="model.selectedPackages"
                    ></display-label>
                  </div>
                  <div class="col-lg-6">
                    <display-label
                      :type="getStatusClass(model.status)"
                      label="Status"
                      :value="model.status"
                    ></display-label>
                  </div>
                </div>

                <div class="row">
                  <div class="col-lg-6">
                    <display-label
                      label="Token Count"
                      :value="model.tokenCount"
                    ></display-label>
                  </div>
                </div>
                <div class="row">
                  <div class="col-lg-6">
                    <display-label
                      label="Created At"
                      :value="model.createdAt"
                    ></display-label>
                  </div>
                  <div class="col-lg-6">
                    <display-label
                      label="Created By"
                      :value="model.createdBy"
                    ></display-label>
                  </div>
                </div>
              </div>

              <hr class="my-4" />
            </form>
          </card>
        </div>
        <div class="col-xl-6 order-xl-1">
          <card shadow type="secondary">
            <template v-slot:header>
              <div class="bg-white border-0">
                <div class="row align-items-center">
                  <div class="col-6">
                    <h3 class="mb-0">Payment Information</h3>
                  </div>
                  <div class="col-6 text-right">
                    <label class="text-sm text-black-50">Payment Type:</label>
                    <label>{{ model.paymentType }}</label>
                  </div>
                </div>
              </div>
            </template>

            <form>
              <!--              <h6 class="heading-small text-muted mb-4">Invoice information</h6>-->
              <div class="pl-lg-4">
                <div class="row">
                  <div class="col-lg-6">
                    <display-label
                      label="Payment Type"
                      :value="model.paymentType"
                    ></display-label>
                  </div>
                  <div class="col-lg-6">
                    <display-label
                      label="Cost (€)"
                      :value="model.cost"
                    ></display-label>
                  </div>
                </div>
                <h6 class="heading-small text-muted mb-4 pt-5">
                  Payment Fields
                </h6>
                <div class="row">
                  <div
                    v-bind:key="key"
                    v-for="(value, key) in model.paymentFields"
                    class="col-lg-6"
                  >
                    <display-label :label="key" :value="value"></display-label>
                  </div>
                </div>
              </div>

              <hr class="my-4" />
            </form>
          </card>
          <!--          <modal notice="notice" :show="true"><h1>Hey</h1></modal>-->
          <modal v-model:show="modalBool">
            <template v-slot:header>
              <h5 class="modal-title" id="exampleModalLabel">
                {{ modalTitle }}
              </h5>
            </template>
            <div>
              {{ modalDescription }}
            </div>
            <template v-slot:footer>
              <base-button type="secondary" @click="modalBool = false"
                >No</base-button
              >
              <submit-button
                :alertMessage="errorMessage"
                :state="loadingProcess"
                @clicked="modalAction()"
                type="primary"
                >Yes</submit-button
              >
            </template>
          </modal>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { store } from "../../../store";
// import InvoiceModal from "../Modals/InvoiceModal";
import DisplayLabel from "../../../components/displayLabel";
import { getStatusClass } from "../../../directives/helper";
import BaseButton from "../../../components/BaseButton";
import FirebaseFunctionsHelper from "../../../plugins/firebaseFunctionsHelper";
export default {
  name: "tables",
  components: { BaseButton, DisplayLabel },
  data() {
    return {
      modalBool: false,
      modalTitle: "",
      modalDescription: "",
      modalAction: null,
      model: {
        cost: 10,
        companyId: "",
        createdAt: "",
        createdBy: "",
        id: "",
        paymentFields: {},
        paymentType: "",
        selectedPackages: "",
        status: "",
        tokenCount: null,
        type: "",
        verifiedAt: "",
        verifiedBy: "",
      },
      loadingProcess: "",
      errorMessage: "",
    };
  },
  mounted() {
    this.getInvoice();
  },
  methods: {
    showModal(title, description, action) {
      this.modalTitle = title;
      this.modalDescription = description;
      this.modalAction = action;
      this.modalBool = true;
    },
    updateInvoice(status) {
      let self = this;
      console.log("updateInvoice", status);
      this.loadingProcess = "processing";
      FirebaseFunctionsHelper.callFunction("updateInvoiceStatus", {
        id: this.model.uid,
        companyId: this.model.companyId,
        status: status,
      })
        .then((result) => {
          // eslint-disable-next-line no-prototype-builtins
          if (result.hasOwnProperty("message")) {
            this.loadingProcess = "failed";
            this.errorMessage = result.message;
            return;
          }

          this.paymentProcess = "done";
          self.getInvoice();
          setTimeout(function () {
            self.paymentProcess = "";
            self.modalBool = false;
          }, 500);
        })
        .catch((error) => {
          self.loadingProcess = "failed";
          self.errorMessage = error.message;
          console.log("submitUser error", error);
        });
    },
    verifyInvoice() {
      this.updateInvoice("VERIFIED");
    },
    rejectInvoice() {
      this.updateInvoice("REJECTED");
    },
    getStatusClass,
    async getInvoice() {
      // let self = this;
      let invoiceId = this.$route.params.id;
      await store.dispatch("fetchInvoice", { id: invoiceId }).then((data) => {
        console.log("invoiceData", data);
        this.model = data;
        this.model.paymentFields = {};
      });
    },
  },
};
</script>
<style></style>
