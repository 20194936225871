<template>
  <div>
    <base-header
      class="header pb-8 pt-5 pt-lg-8 d-flex align-items-center"
      style="
        min-height: 200px;
        background-image: url(img/theme/profile-cover.jpg);
        background-size: cover;
        background-position: center top;
      "
    >
      <!-- Mask -->
      <span class="mask bg-gradient-blue opacity-8"></span>
      <!-- Header container -->
      <div class="container-fluid d-flex align-items-center">
        <div class="row">
          <div class="col-12">
            <h1 class="display-2 text-white">
              {{ companyData.companyName }}
            </h1>
            <!--            <p class="text-white mt-0 mb-5">-->
            <!--              This is your profile page. You can see the progress you've made-->
            <!--              with your work and manage your projects or assigned tasks-->
            <!--            </p>-->
            <!--            <a href="#!" class="btn btn-info">Edit profile</a>-->
          </div>
        </div>
      </div>
    </base-header>

    <div class="container-fluid mt--7">
      <div class="row mb-5">
        <div class="col-xl-4 order-xl-2 mb-5 mb-xl-0">
          <company-statistics-card></company-statistics-card>
        </div>

        <div class="col-xl-8 order-xl-1">
<!--          <div class="pb-2">-->
<!--            <router-link class="btn btn-primary" :to="'/companies/'+companyData.id+'/appointments'">Appointments</router-link>-->
<!--            <router-link class="btn btn-primary" :to="'/companies/'+companyData.id+'/invoices'">Invoices</router-link>-->
<!---->
<!--          </div>-->
          <company-details-card :companyData="companyData"></company-details-card>
          <br />
          <company-booking-settings-card></company-booking-settings-card>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import CompanyDetailsCard from "./Cards/CompanyDetailsCard";
import CompanyStatisticsCard from "./Cards/CompanyStatisticsCard";
import CompanyBookingSettingsCard from "./Cards/CompanyBookingSettingsCard";
export default {
  name: "company",
  components: {
    CompanyStatisticsCard,
    CompanyDetailsCard,
    CompanyBookingSettingsCard,
  },
  data() {
    return {
      nav: null,
      companyData: {},
    };
  },
  mounted() {
    // var self = this;
    this.getCompany();
  },
  methods: {
    async getCompany() {
      let self = this;
      await this.$store
        .dispatch("fetchCompany", { id: this.$route.params.id })
        .then((data) => {
          console.log("data", data);
          self.companyData = data;
        });
    },
  },
};
</script>
<style></style>
