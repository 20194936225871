<template>
  <div>
    <div class="row align-items-center p-4 " style="width: 100%;margin: 0;">
      <div class="col-12 col-md-6" style="text-align: right; float: left">
        <div
          class="col-12 col-md-9 p-2"
          style="margin: 0 auto; text-align: left"
        >
          <h1
            class="card-title"
            style="font-family: Pacifico, cursive; font-size: 35px"
          >
            It's Easy as 1, 2, 3…
          </h1>
          <div class="row pt-4">
            <div class="col-1">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink"
                version="1.1"
                id="Layer_1"
                x="0px"
                y="0px"
                width="31px"
                height="31px"
                viewBox="0 0 31 31"
                style="enable-background: new 0 0 31 31"
                xml:space="preserve"
              >
                <path
                  class="st0"
                  style="fill: #3f9b52"
                  d="M15.5,0C7,0,0,7,0,15.5C0,24,7,31,15.5,31C24,31,31,24,31,15.5C31,7,24,0,15.5,0z M11,8.9  c0-0.5,0.2-0.6,0.6-0.6H12c1.2,0,2.1-0.6,2.5-1.7C14.8,6.1,15,6,15.4,6H17c0.3,0,0.6,0.3,0.6,0.6v17c0,0.3-0.3,0.6-0.6,0.6h-2.6  c-0.3,0-0.6-0.3-0.6-0.6V11.4h-2.2c-0.3,0-0.6-0.2-0.6-0.6V8.9z"
                ></path>
              </svg>
            </div>
            <div class="col-11">
              <div
                class="col-12 sub-title text-black-50 font-weight-800 text-lg"
              >
                Download the App
              </div>
              <div class="col-12">
                <div class="description pt-1 text-muted text-md">
                  Follow the easy steps on the App to create your company. Upon registration we will give you
                  exactly what you need to explore and use our services.
                </div>
              </div>
            </div>
          </div>
          <div class="row pt-4">
            <div class="col-1">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink"
                version="1.1"
                id="Layer_1"
                x="0px"
                y="0px"
                width="31px"
                height="31px"
                viewBox="0 0 31 31"
                style="enable-background: new 0 0 31 31"
                xml:space="preserve"
              >
                <!--              <style type="text/css">-->
                <!--                .st0 {-->
                <!--                  fill: #3f9b52;-->
                <!--                }-->
                <!--              </style>-->
                <path
                  class="st0"
                  style="fill: #3f9b52"
                  d="M15.5,0C7,0,0,7,0,15.5C0,24,7,31,15.5,31C24,31,31,24,31,15.5C31,7,24,0,15.5,0z M9.7,24.2  c-0.3,0-0.6-0.3-0.6-0.6v-1.9c0-0.6,0.3-0.9,0.5-1l5-4.4c2.2-1.8,3.5-3.2,3.5-4.8c0-1.6-1.2-2.5-2.7-2.5c-1.8,0-2.7,1.4-3.1,2.5  c-0.2,0.6-0.6,0.9-1.1,0.7l-1.9-0.6c-0.4-0.1-0.6-0.5-0.5-0.9c0.6-2.6,2.9-5.1,6.8-5.1c3.6,0,6.5,2.1,6.5,5.7c0,2.7-2.1,4.9-4.7,7  l-2.9,2.5h7.3c0.3,0,0.6,0.3,0.6,0.6v2.2c0,0.3-0.2,0.6-0.6,0.6H9.7z"
                ></path>
              </svg>
            </div>
            <div class="col-11">
              <div class="col-12 text-black-50 font-weight-800 text-lg">
                Go Online - Import your Clients
              </div>
              <div class="col-12">
                <div class="description pt-1 text-md text-muted">
                  Turn online bookings ON and import your clients from your contacts.
                </div>
              </div>
            </div>
          </div>
          <div class="row pt-4">
            <div class="col-1">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink"
                version="1.1"
                id="Layer_1"
                x="0px"
                y="0px"
                width="31px"
                height="31px"
                viewBox="0 0 31 31"
                style="enable-background: new 0 0 31 31"
                xml:space="preserve"
              >
                <!--              <style type="text/css">-->
                <!--                .st0 {-->
                <!--                  fill: #3f9b52;-->
                <!--                }-->
                <!--              </style>-->
                <path
                  class="st0"
                  style="fill: #3f9b52"
                  d="M15.5,0C7,0,0,7,0,15.5C0,24,7,31,15.5,31C24,31,31,24,31,15.5C31,7,24,0,15.5,0z M15.3,12.9  c1.4,0,2.5-0.8,2.5-2.2c0-1.2-1-2.1-2.4-2.1c-1.4,0-2.2,1-2.8,1.9c-0.3,0.4-0.5,0.5-0.9,0.3L9.9,10C9.5,9.9,9.3,9.5,9.5,9.1  c0.7-1.7,2.7-3.7,6.2-3.7c3.3,0,5.8,1.9,5.8,4.8c0,1.7-1.1,3.2-2.6,3.7c2,0.5,3.5,2,3.5,4.6c0,3.6-2.9,5.9-7,5.9  c-4.2,0-7.2-2.5-7.2-6v-0.4c0-0.3,0.3-0.5,0.6-0.5h2.7c0.3,0,0.6,0.2,0.6,0.5v0.2c0,1.6,1.2,3,3.3,3c2,0,3.1-1.2,3.1-2.7  c0-1.4-1.1-2.5-3.1-2.5h-0.7c-0.3,0-0.6-0.3-0.6-0.6v-1.8c0-0.3,0.3-0.6,0.6-0.6H15.3z"
                ></path>
              </svg>
            </div>
            <div class="col-11">
              <div
                class="col-12 sub-title text-black-50 font-weight-800 text-lg"
              >
                Share the Link
              </div>
              <div class="col-12">
                <div class="description pt-1 text-muted text-md">
                  Share your unique Booking Link with your clients and start having appointments right away.
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12 col-md-6 p-2" style="float: left;    text-align: center;">
        <img
          style="border-radius: 20px"
          class="layout-img"
          src="../assets/img/idea-concept-with-light-bulb.jpg"
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "numbered-steps",
};
</script>

<style scoped>

.layout-img{
  max-width: 550px;
}

@media (max-width: 1256px) {
  .layout-img {
    width: 100%;
  }
}
</style>
