<template>
  <card shadow type="secondary" class="mb-8">
    <template v-slot:header>
      <div class="bg-white border-0">
        <div class="row align-items-center">
          <div class="col-8">
            <h3 class="mb-0">Booking Settings</h3>
          </div>
          <div class="col-4 text-right">
            <base-button
              type="primary"
              @click="getBookingSettings()"
              class="btn btn-sm btn-primary"
              >Refresh</base-button
            >
          </div>
        </div>
      </div>
    </template>

    <form>
      <!-- Address -->
      <h6 class="heading-small text-muted mb-4">Booking Details</h6>
      <div class="pl-lg-4">
        <div class="row">
          <div class="col-lg-6">
            <base-input
              :disabled="isDisabled"
              alternative=""
              label="Service Location"
              placeholder="Service Location"
              input-classes="form-control-alternative"
              v-model="bookingSettings.serviceLocation"
            />
          </div>
          <div class="col-lg-6">
            <base-input
              :disabled="isDisabled"
              alternative=""
              label="Appointment Slot"
              placeholder="Appointment Slot"
              input-classes="form-control-alternative"
              v-model="bookingSettings.appointmentSlot"
            />
          </div>
        </div>
        <div class="row">
          <div class="col-lg-6">
            <base-switch
              :disabled="isDisabled"
              label="Enable SMS Notifications"
              v-model="bookingSettings.smsNotificationsEnabled"
              :value="bookingSettings.smsNotificationsEnabled"
            ></base-switch>
          </div>
        </div>
      </div>
      <hr class="my-4" />
      <template
        v-if="
          ['BUSINESS_AND_MOBILE', 'BUSINESS'].includes(
            bookingSettings.serviceLocation
          )
        "
      >
        <h6 class="heading-small text-muted mb-4">Business Services</h6>
        <div class="pl-lg-4">
          <div class="row">
            <div class="col-lg-6">
              <base-switch
                :disabled="isDisabled"
                label="Auto Approve Appointments"
                v-model="bookingSettings.autoApprove"
                :value="bookingSettings.autoApprove"
              ></base-switch>
            </div>
            <div class="col-lg-6">
              <base-input
                :disabled="isDisabled"
                alternative=""
                label="Booking for Future"
                placeholder="Booking for Future"
                input-classes="form-control-alternative"
                v-model="bookingSettings.futureBooking"
              />
            </div>
            <div class="col-lg-6">
              <base-input
                :disabled="isDisabled"
                alternative=""
                label="Booking in Advance Future"
                placeholder="Booking in Advance"
                input-classes="form-control-alternative"
                v-model="bookingSettings.bookingInAdvance"
              />
            </div>
            <div class="col-lg-6">
              <base-input
                :disabled="isDisabled"
                alternative=""
                label="Cancellation Policy"
                placeholder="Cancellation Policy"
                input-classes="form-control-alternative"
                v-model="bookingSettings.cancellationInAdvance"
              />
            </div>
          </div>
        </div>
      </template>
      <hr class="my-4" />
      <template
        v-if="
          ['BUSINESS_AND_MOBILE', 'MOBILE'].includes(
            bookingSettings.serviceLocation
          )
        "
      >
        <h6 class="heading-small text-muted mb-4">Remote Services</h6>
        <div class="pl-lg-4">
          <div class="row">
            <div class="col-lg-6">
              <base-switch
                :disabled="isDisabled"
                label="Auto Approve Remote Appointments"
                v-model="bookingSettings.mobileAutoApprove"
                :value="bookingSettings.mobileAutoApprove"
              ></base-switch>
            </div>
            <div class="col-lg-6">
              <base-input
                :disabled="isDisabled"
                alternative=""
                label="Booking for Future"
                placeholder="Remote Booking for Future"
                input-classes="form-control-alternative"
                v-model="bookingSettings.mobileFutureBooking"
              />
            </div>
          </div>
          <div class="row">
            <div class="col-lg-6">
              <base-input
                :disabled="isDisabled"
                alternative=""
                label="Booking in Advance Future"
                placeholder="Remote Booking in Advance"
                input-classes="form-control-alternative"
                v-model="bookingSettings.mobileBookingInAdvance"
              />
            </div>
            <div class="col-lg-6">
              <base-input
                :disabled="isDisabled"
                alternative=""
                label="Cancellation Policy"
                placeholder="Remote Cancellation Policy"
                input-classes="form-control-alternative"
                v-model="bookingSettings.mobileCancellationInAdvance"
              />
            </div>
          </div>
          <div class="row">
            <div class="col-lg-6">
              <base-input
                :disabled="isDisabled"
                alternative=""
                label="Travel Time"
                placeholder="Travel Time"
                input-classes="form-control-alternative"
                v-model="bookingSettings.travelTime"
              />
            </div>
            <div class="col-lg-6">
              <base-input
                :disabled="isDisabled"
                alternative=""
                label="Travel Fee"
                placeholder="Travel Fee"
                input-classes="form-control-alternative"
                v-model="bookingSettings.travelFee"
              />
            </div>
          </div>
          <div class="col-12 text-right">
            <base-button @click="clearForm" outline type="info"
              >Reset</base-button
            >
            <submit-button
              @click="saveBookingSettings"
              :alertMessage="alertMessage"
              :state="loadingProcess"
              >Save</submit-button
            >
          </div>
        </div>
      </template>

      <!-- Description -->
    </form>
  </card>
</template>
<script>
export default {
  name: "company-booking-settings-card",
  data() {
    return {
      isDisabled: true,
      alertMessage: "",
      loadingProcess: "",
      bookingSettings: {
        appointmentSlot: null,
        autoApprove: null,
        bookingInAdvance: null,
        cancellationInAdvance: null,
        futureBooking: null,
        loyaltyPercentage: null,
        loyaltyProgramEnable: null,
        mobileAutoApprove: null,
        mobileBookingInAdvance: null,
        mobileCancellationInAdvance: null,
        mobileFutureBooking: null,
        serviceLocation: " ",
        smsNotificationsEnabled: null,
        travelFee: null,
        travelTime: null,
      },
    };
  },
  mounted() {
    // var self = this;
    console.log(this.$route.params);
    this.getBookingSettings();
    // this.mapCompany();
  },
  methods: {
    saveBookingSettings() {
      this.loadingProcess = "processing";
      setTimeout(() => {
        this.loadingProcess = "failed";
        this.alertMessage = "Not implemented yet.";
        //Add Tokens
      }, 2000);
    },
    clearForm() {
      this.loadingProcess = "";
      this.alertMessage = "";
    },
    async getBookingSettings() {
      let self = this;
      await this.$store
        .dispatch("fetchBookingSettings", { id: this.$route.params.id })
        .then((data) => {
          console.log("fetchBookingSettings", data);
          self.bookingSettings = data;
          self.bookingSettings["mobileFutureBooking"] =
            data["mobileFutureBooking"] / 60 / 24 + " days ahead";
          self.bookingSettings["futureBooking"] =
            data["futureBooking"] / 60 / 24 + " days ahead";
          self.bookingSettings["cancellationInAdvance"] =
            data["cancellationInAdvance"] + " minutes before";
          self.bookingSettings["mobileCancellationInAdvance"] =
            data["mobileCancellationInAdvance"] + " minutes before";
          self.bookingSettings["bookingInAdvance"] =
            data["bookingInAdvance"] + " minutes before";
          self.bookingSettings["mobileBookingInAdvance"] =
            data["mobileBookingInAdvance"] + " minutes before";
          self.bookingSettings["travelFee"] = "€" + data["travelFee"];
          self.bookingSettings["travelTime"] = data["travelTime"] + " minutes";
        });
    },
  },
};
</script>
<style></style>
