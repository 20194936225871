<template>
  <div class="card shadow" :class="type === 'dark' ? 'bg-default' : ''">
    <div
      class="card-header border-0"
      :class="type === 'dark' ? 'bg-transparent' : ''"
    >
      <div class="row align-items-center">
        <div class="col">
          <h3 class="mb-0" :class="type === 'dark' ? 'text-white' : ''">
            {{ title }}
          </h3>
        </div>
        <div class="col text-right">
          <base-button @click="refresh()" type="primary" size="sm"
            >Refresh</base-button
          >
          <base-button tag="a" href="/invoices/new" type="success" size="sm"
            >New Purchase</base-button
          >
        </div>
      </div>
    </div>

    <div class="table-responsive">
      <base-table
        class="table align-items-center table-flush"
        :class="type === 'dark' ? 'table-dark' : ''"
        :thead-classes="type === 'dark' ? 'thead-dark' : 'thead-light'"
        tbody-classes="list"
        :data="invoices"
      >
        <template v-slot:columns>
          <th>Company</th>
          <th>Name</th>
          <th>Cost</th>
          <th>Status</th>
          <th>Completion</th>
          <th></th>
        </template>

        <template v-slot:default="row">
          <th scope="row">
            <div class="media align-items-center">
              <div class="media-body">
                <span class="name mb-0 text-sm">{{
                  row.item.companyName
                }}</span>
              </div>
            </div>
          </th>
          <th scope="row">
            <div class="media align-items-center">
              <div class="media-body">
                <span class="name mb-0 text-sm">{{
                  row.item.selectedPackages
                }}</span>
              </div>
            </div>
          </th>
          <td class="budget">€{{ row.item.cost }}</td>
          <td>
            <display-label
              :type="getStatusClass(row.item.status)"
              :value="row.item.status"
            ></display-label>
          </td>
          <td>
            <div class="d-flex align-items-center">
              <!--              <span class="completion mr-2">{{ row.item.completion }}%</span>-->
              <!--              <div>-->
              <!--                <base-progress-->
              <!--                  :type="row.item.statusType"-->
              <!--                  :show-percentage="false"-->
              <!--                  class="pt-0"-->
              <!--                  :value="row.item.completion"-->
              <!--                />-->
              <!--              </div>-->
              <div class="name mb-0 text-md-left">{{ row.item.createdAt }}</div>
            </div>
          </td>

          <td class="text-right">
            <!--            <base-button v-if="row.item.status ==='PENDING'" @click="updateInvoice(row.item, 'VERIFIED')" class="bg-gradient-success border-0" size="md">Verify</base-button>-->
            <base-button
              v-if="row.item.status === 'PENDING'"
              class="text-right"
              @click="
                showModal(
                  'Verify Invoice',
                  'Are you sure you want to verify this invoice?',
                  updateInvoice,
                  { item: row.item, status: 'VERIFIED' }
                )
              "
              type="success"
              size="md"
              >Verify</base-button
            >
            <base-button
              v-if="row.item.status === 'PENDING'"
              type="danger"
              class="text-white"
              size="md"
              >Reject</base-button
            >
            <base-button
              tag="a"
              :href="'/system-invoices/' + row.item.id"
              type="primary"
              class="text-white"
              size="md"
              >View</base-button
            >
          </td>
        </template>
      </base-table>
      <modal v-model:show="modalBool">
        <template v-slot:header>
          <h5 class="modal-title" id="exampleModalLabel">
            {{ modalTitle }}
          </h5>
        </template>
        <div>
          {{ modalDescription }}
        </div>
        <template v-slot:footer>
          <base-button type="secondary" @click="modalBool = false"
            >No</base-button
          >
          <submit-button
            :alertMessage="errorMessage"
            :state="loadingProcess"
            @clicked="modalAction()"
            type="primary"
            >Yes</submit-button
          >
        </template>
      </modal>
    </div>

    <!--    <div-->
    <!--      class="card-footer d-flex justify-content-end"-->
    <!--      :class="type === 'dark' ? 'bg-transparent' : ''"-->
    <!--    >-->
    <!--      <base-pagination total="30"></base-pagination>-->
    <!--    </div>-->
  </div>
</template>
<script>
import { store } from "../../store";
import { getStatusClass } from "../../directives/helper";
import DisplayLabel from "../../components/displayLabel";
import FirebaseFunctionsHelper from "../../plugins/firebaseFunctionsHelper";
import BaseButton from "../../components/BaseButton";
import SubmitButton from "../../components/SubmitButton";
import Modal from "../../components/Modal";

export default {
  name: "system-invoices-table",
  components: { Modal, SubmitButton, BaseButton, DisplayLabel },
  props: {
    type: {
      type: String,
    },
    title: String,
  },
  data() {
    return {
      modalAction: null,
      modalBool: false,
      modalParams: {},
      loadingProcess: "",
      errorMessage: "",
      invoices: [],
      tableData: [],
    };
  },
  mounted() {
    this.getInvoices();
  },
  methods: {
    async updateInvoice() {
      let self = this;
      this.loadingProcess = "processing";
      let payload = {
        id: this.modalParams.item.uid,
        companyId: this.modalParams.item.companyId,
        status: this.modalParams.status,
      };

      // eslint-disable-next-line no-unreachable
      FirebaseFunctionsHelper.callFunction("updateInvoiceStatus", payload)
        .then((result) => {
          self.modalParams = {};
          // eslint-disable-next-line no-prototype-builtins
          if (result.hasOwnProperty("message")) {
            this.loadingProcess = "failed";
            this.errorMessage = result.message;
            return;
          }

          this.paymentProcess = "done";
          this.getInvoices();
          setTimeout(function () {
            self.paymentProcess = "";
            self.modalBool = false;
          }, 500);
        })
        .catch((error) => {
          self.loadingProcess = "failed";
          self.errorMessage = error.message;
          console.log("submitUser error", error);
          self.modalParams = {};
        });
    },
    refresh() {
      this.invoices = [];
      this.getInvoices();
    },
    getStatusClass,
    async getInvoices() {
      // let self = this;
      await store.dispatch("fetchInvoices").then((data) => {
        console.log("data", data);
        this.invoices = data;
        this.mapInvoices();
      });
    },
    mapInvoices() {
      this.invoices.forEach((invoice) => {
        let company = this.$store.state.system.companies.find(
          (v) => v.id === invoice.companyId
        );
        invoice.companyName = company.companyName;
      });
    },
    showModal(title, description, action, params) {
      this.modalTitle = title;
      this.modalDescription = description;
      this.modalAction = action;
      this.modalBool = true;
      this.modalParams = params;
    },
  },
};
</script>
<style></style>
