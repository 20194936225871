<template>
  <div class="container">
    <div class="row pt-5 pb-5">
      <div class="col">
        <br />
        <div class="c-cta -main -business">
          <div :class="' c-cta_bg -bgTealDark ' + bgColor"></div>
          <div class="c-cta_inner">
            <h3 class="t-title text-white -f56 pb-3">
              Register Now <br />
              To Get Our Welcome Bonus.
            </h3>

<!--            scroll('download-apps') -->

            <base-button tag="a" href="/contact-us" class="" size="lg" type="secondary">
              Start for FREE
            </base-button>
          </div>
          <div class="c-cta_image">
            <img
              loading="lazy"
              src="../assets/img/appointmepreview.png"
              alt="3.0 CTA_US"
              title=""
            />
          </div>
        </div>
      </div>
    </div>
    <br />
    <br />
  </div>
</template>

<script>
import BaseButton from "./BaseButton";
export default {
  name: "action-box-with-photo",
  components: { BaseButton },
  props: {
    bgColor: {
      type: String,
      description: "Background color class",
    },
    fontColor: {
      type: String,
      description: "font color class",
    },
    isDark: {
      type: Boolean,
      default: false,
    },
    src: {
      type: String,
      description: "Icon for feature",
    },
  },
  methods: {
    scroll(id) {
      document.getElementById(id).scrollIntoView({
        behavior: "smooth"
      });
    },
  },
};
</script>

<style scoped>
.c-cta {
  position: relative;
}

.c-cta_bg {
  background-image: url(https://booksy.com/biz/build/website/images/wave.abbfb830.svg);
  background-repeat: no-repeat;
  background-position: 0 100%;
  border-radius: 40px;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.c-cta_inner {
  position: relative;
  padding: 72px 100px 124px;
}
.c-cta .t-title {
  max-width: 50%;
}
.c-cta.-main .c-cta_image {
  position: absolute;
  right: 70px;
  bottom: -60px;
  height: 500px;
}

.c-cta.-main .c-cta_image img {
  height: 100%;
}
.c-cta .t-title {
  max-width: 50%;
}
.c-cta_bg {
  background-image: url(https://booksy.com/biz/build/website/images/wave.abbfb830.svg);
  background-repeat: no-repeat;
  background-position: 0 100%;
  border-radius: 40px;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.t-title.-f56 {
  font-size: 50px;
  line-height: 1.1428571429;
  letter-spacing: -0.8px;
}

.c-cta .t-title {
  max-width: 50%;
}

@media (max-width: 1008px) {
  .c-cta_image {
    position: relative !important;
    right: 0px !important;
    bottom: 0 !important;
  }

  h3.t-title.text-white.-f56.pb-3 {
    max-width: 100% !important;
  }

  .c-cta_inner {
    padding: 42px !important;
  }

  .c-cta.-main .c-cta_image img {
    width: 100% !important;
  }

  .t-title.-f56 {
    font-size: 32px !important;
  }
}
</style>
