import ChartJS from "chart.js/auto";
export const appointmentsCountChart = {
  createChart(chartId, labels, data) {
    const chartColor = "#FFFFFF";
    const fallBackColor = "#f96332";
    const color = this.color || fallBackColor;
    const ctx = document.getElementById(chartId).getContext("2d");
    const gradientStroke = ctx.createLinearGradient(500, 0, 100, 0);
    gradientStroke.addColorStop(0, color);
    gradientStroke.addColorStop(1, chartColor);

    new ChartJS(ctx, {
      type: "bar",
      data: {
        labels: labels,
        datasets: [
          {
            label: "Confirmed",
            tension: 0.4,
            borderWidth: 0,
            pointRadius: 0,
            backgroundColor: "#3dcb69",
            data: data["VERIFIED"],
            maxBarThickness: 30,
          },
          {
            label: "No show",
            tension: 0.4,
            borderWidth: 0,
            pointRadius: 0,
            backgroundColor: "#000000",
            data: data["NO_SHOW"],
            maxBarThickness: 30,
          },
          {
            label: "Canceled",
            tension: 0.4,
            borderWidth: 0,
            pointRadius: 0,
            backgroundColor: "#f52727",
            data: data["CANCELED"],
            maxBarThickness: 30,
          },
          {
            label: "Pending",
            tension: 0.4,
            borderWidth: 0,
            pointRadius: 0,
            backgroundColor: "#efd545",
            data: data["PENDING"],
            maxBarThickness: 30,
          },
        ],
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        legend: {
          display: false,
        },
        tooltips: {
          enabled: true,
          mode: "index",
          intersect: false,
        },
        // scales: {
        //   yAxes: [
        //     {
        //       gridLines: {
        //         borderDash: [2],
        //         borderDashOffset: [2],
        //         drawBorder: false,
        //         drawTicks: false,
        //         lineWidth: 1,
        //         zeroLineWidth: 0,
        //         zeroLineBorderDash: [2],
        //         zeroLineBorderDashOffset: [2],
        //       },
        //       ticks: {
        //         beginAtZero: true,
        //         padding: 10,
        //         fontSize: 13,
        //         fontColor: "#8898aa",
        //         fontFamily: "Open Sans, sans-serif",
        //         callback: function (value) {
        //           if (!(value % 10)) {
        //             return value;
        //           }
        //         },
        //       },
        //     },
        //   ],
        //   xAxes: [
        //     {
        //       gridLines: {
        //         drawBorder: false,
        //         drawOnChartArea: false,
        //         drawTicks: true,
        //       },
        //       ticks: {
        //         padding: 10,
        //         fontSize: 12,
        //         fontColor: "#8898aa",
        //         fontFamily: "Open Sans, sans-serif",
        //       },
        //     },
        //   ],
        // },
      },
    });
  },
};

const funcs = {
  appointmentsCountChart() {},
};

export default funcs;
