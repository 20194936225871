import { createStore } from "vuex";
import VuexPersistence from "vuex-persist";
import { getAuth } from "firebase/auth";
import { fireApp } from "../main";
import {
  collection,
  collectionGroup,
  doc,
  getDoc,
  getDocs,
  getFirestore,
  limit,
  orderBy,
  query,
  where
} from "firebase/firestore";
import moment from "moment";
import { fetchAndActivate, getRemoteConfig, getValue } from "firebase/remote-config";

function getDocReference(path, id) {
  let fireS = getFirestore(fireApp);
  return doc(collection(fireS, path), id);
}

export const store = createStore({
  plugins: [new VuexPersistence().plugin],
  state: {
    companyMonthlyStatistics: null,
    expiryDate: new Date(),
    locale: "en",
    system: {
      statistics: {},
      companies: [],
      systemUsers: [],
    },
    company: {},
    showLogin: false,
    user: {
      claims: null,
      loggedIn: false,
      data: null,
      private: null,
      clientData: null,
    },
  },
  mutations: {
    setExpiryDate: (state) => {
      // Create a date
      // Set the state
      state.expiryDate = moment(new Date()).add(30, "m").toDate();
    },
    SET_LOCALE(state, lang) {
      state.locale = lang;
    },
    SET_COMPANY_BIG_QUERY_STATISTICS(state, statistics) {
      state.companyMonthlyStatistics = statistics;
    },
    RESET_STATE(state) {
      state.user = {};
      state.company = {};
      state.companyMonthlyStatistics = null;
      state.system = {
        statistics: {},
        companies: [],
        systemUsers: [],
      };
    },
    SET_LOGGED_IN(state, data) {
      state.user.loggedIn = data;
    },
    SET_USER(state, data) {
      state.user.data = data;
    },
    SET_CLIENT(state, data) {
      state.user.clientData = data;
    },
    SET_USER_PRIVATE(state, data) {
      state.user.private = data;
    },
    SET_USER_CLAIMS(state, data) {
      state.user.claims = data;
    },
    SET_STATISTICS(state, data) {
      state.system.statistics = data;
    },
    GET_COMPANY(state, data) {
      state.company = data;
    },
    GET_BOOKING_SETTINGS(state, data) {
      state.bookkingSettings = data;
    },
    GET_COMPANY_STATISTICS(state, data) {
      state.companyStatistics = data;
    },
    SET_SYSTEM_USERS(state, data) {
      state.system.systemUsers = data;
    },
    SET_COMPANIES(state, data) {
      state.system.companies = data;
    },
  },
  getters: {
    isCacheExpired: (state) => new Date(state.expiryDate) < new Date(),
  },
  actions: {
    setCompanyMonthlyStatistics({ commit }, statistics) {
      console.log("statistics", statistics);
      commit("SET_COMPANY_BIG_QUERY_STATISTICS", statistics);
      commit("setExpiryDate");
    },
    setLocale({ commit }, lang) {
      commit("SET_LOCALE", lang);
    },
    async getClaims() {
      return await getAuth(fireApp)
        .currentUser.getIdTokenResult()
        .then((idTokenResult) => {
          return idTokenResult.claims;
        })
        .catch((error) => {
          console.log(error);
          return null;
        });
    },
    isLoggedIn({ commit }) {
      console.log("im here");

      if (this.state.user.loggedIn) {
        return true;
      }
      let currUser = getAuth(fireApp).currentUser;
      console.log("currUser", currUser);
      commit("SET_LOGGED_IN", currUser != null);
      return currUser != null;
    },
    signOut({ commit }) {
      getAuth(fireApp)
        .signOut()
        .then(() => {
          console.log("logged out");
          commit("RESET_STATE");
          console.log("this.$route", this.$route);
          if (this.$route.name !== "QuickBook") {
            console.log("going home");
            this.$router.push({ path: "home" });
          }
        });
    },
    async fetchSystemStatistics({ commit }) {
      let systemRef = getDocReference("system", "statistics");
      await getDoc(systemRef).then((doc) => {
        if (doc) {
          let statistics = doc.data();
          commit("SET_STATISTICS", statistics);
        }
      });
    },
    // eslint-disable-next-line no-unused-vars
    async fetchCompanyServices({ commit }, data) {
      let companyId;
      // eslint-disable-next-line no-prototype-builtins
      if (data && data.hasOwnProperty("id")) {
        companyId = data.id;
      } else {
        companyId = this.state.user.claims.companyId;
      }

      let fireS = getFirestore(fireApp);

      let collectionRef = collection(
        fireS,
        "companies/" + companyId + "/services"
      );
      const docsQuery = query(
        collectionRef,
        where("serviceLocation", "==", data.type),
        where("status", "==", "ACTIVE"),
        limit(20)
      );
      return await getDocs(docsQuery).then((docs) => {
        let docsData = [];
        docs.forEach((doc) => {
          const docData = doc.data();
          docData["id"] = doc.id;
          docsData.push(docData);
        });
        return docsData;
      });
    },
    async fetchCompany({ commit }, data) {
      let companyId;
      // eslint-disable-next-line no-prototype-builtins
      if (data && data.hasOwnProperty("id")) {
        companyId = data.id;
      } else {
        companyId = this.state.user.claims.companyId;
      }

      let fireS = getFirestore(fireApp);
      let userQuery = doc(collection(fireS, "companies"), companyId);
      return await getDoc(userQuery).then((doc) => {
        if (doc) {
          let company = doc.data();
          company["id"] = doc.id
          // eslint-disable-next-line no-prototype-builtins
          if (data && data.hasOwnProperty("id")) {
            return company;
          } else {
            commit("GET_COMPANY", company);
          }
        }
        return null;
      });
    },
    // eslint-disable-next-line no-unused-vars
    async fetchAvailableEmployees({ commit }, data) {
      let companyId;
      // eslint-disable-next-line no-prototype-builtins
      if (data && data.hasOwnProperty("id")) {
        companyId = data.id;
      } else {
        companyId = this.state.user.claims.companyId;
      }

      let fireS = getFirestore(fireApp);

      let collectionRef = collection(
        fireS,
        "companies/" + companyId + "/employees"
      );
      let docsQuery = query(collectionRef);

      data.services.forEach((service) => {
        docsQuery = query(
          docsQuery,
          where("serviceIds." + service.id, "==", true)
        );
      });

      docsQuery = query(docsQuery, where("status", "==", "ACTIVE"), limit(20));

      return await getDocs(docsQuery).then((docs) => {
        let docsData = [];
        docs.forEach((doc) => {
          const docData = doc.data();
          docData["id"] = doc.id;
          docsData.push(docData);
        });
        return docsData;
      });
    },
    // eslint-disable-next-line no-unused-vars
    // async fetchCompanyMonthlyStatistics({ commit }, data) {
    //   let companyId = this.state.user.claims.companyId;
    //   let fireS = getFirestore(fireApp);
    //
    //   let collectionRef = collection(
    //     fireS,
    //     "companies/" + companyId + "/monthlyStatistics"
    //   );
    //   const docsQuery = query(
    //     collectionRef,
    //     where(documentId(), "in", data.docs),
    //     limit(10)
    //   );
    //   return await getDocs(docsQuery).then((docs) => {
    //     let docsData = {};
    //     docs.forEach((doc) => {
    //       docsData[doc.id] = doc.data();
    //     });
    //     return docsData;
    //   });
    // },
    async fetchCompanyStatistics({ commit }, data) {
      let companyId;
      // eslint-disable-next-line no-prototype-builtins
      if (data && data.hasOwnProperty("companyId")) {
        companyId = data.companyId;
      } else {
        companyId = this.state.user.claims.companyId;
      }

      let statisticsDoc = getDocReference(
        "companies/" + companyId + "/companyPrivate",
        "statistics"
      );

      return await getDoc(statisticsDoc).then((doc) => {
        if (doc) {
          let statistics = doc.data();

          commit("GET_COMPANY_STATISTICS", statistics);
          return statistics;
          // console.log('setting User')
        }
      });
    },
    // eslint-disable-next-line no-unused-vars
    async fetchInvoice({ commit }, data) {
      let fireS = getFirestore(fireApp);
      let invoicesRef = collectionGroup(fireS, "invoices");
      //
      const docsQuery = query(
        invoicesRef,
        where("uid", "==", data.id),
        limit(1)
      );

      return await getDocs(docsQuery).then((docs) => {
        if (docs.docs.length === 0) {
          return null;
        }
        let doc = docs.docs[0];
        if (doc) {
          let invoiceData = doc.data();
          invoiceData["createdAt"] = moment(
            invoiceData["createdAt"].toDate()
          ).format("DD/MM/YYYY HH:mm");
          if (data["status"] === "VERIFIED") {
            invoiceData["verifiedAt"] = moment(
              invoiceData["verifiedAt"].toDate()
            ).format("DD/MM/YYYY HH:mm");
          } else if (data["status"] === "REJECTED") {
            invoiceData["rejectedAt"] = moment(
              invoiceData["rejectedAt"].toDate()
            ).format("DD/MM/YYYY HH:mm");
          }

          return invoiceData;
        }
        return null;
      });
    },
    async fetchInvoices() {
      let fireS = getFirestore(fireApp);
      let collectionRef = collectionGroup(fireS, "invoices");
      const docsQuery = query(
        collectionRef,
        orderBy("createdAt", "desc"),
        where("status", "in", ["VERIFIED", "PENDING", "CANCELED", "REJECTED"]),
        limit(20)
      );

      return await getDocs(docsQuery).then((docs) => {
        let invoices = [];
        docs.forEach((doc) => {
          let data = doc.data();
          data["id"] = doc.id;
          data["createdAt"] = moment(data["createdAt"].toDate()).format(
            "DD/MM/YYYY HH:mm"
          );
          if (data["status"] === "VERIFIED" && Object.prototype.hasOwnProperty.call(data,"verifiedAt")) {
            data["verifiedAt"] = moment(data["verifiedAt"].toDate()).format(
              "DD/MM/YYYY HH:mm"
            );
          } else if (data["status"] === "REJECTED" && Object.prototype.hasOwnProperty.call(data,"rejectedAt")) {
            data["rejectedAt"] = moment(data["rejectedAt"].toDate()).format(
              "DD/MM/YYYY HH:mm"
            );
          }
          invoices.push(data);
        });
        return invoices;
      });
    },
    async fetchBillingSettings() {
      let companyId = this.state.user.claims.companyId;
      let billingSettingsDoc = getDocReference(
        "companies/" + companyId + "/companyPrivate/",
        "billingSettings"
      );
      return await getDoc(billingSettingsDoc).then((doc) => {
        if (doc.exists()) {
          return doc.data();
        } else {
          return null;
        }
      });

    },
    async fetchCompanyInvoices() {
      let fireS = getFirestore(fireApp);
      let companyId = this.state.user.claims.companyId;

      let collectionRef = collection(
        fireS,
        "companies/" + companyId + "/invoices"
      );
      const docsQuery = query(
        collectionRef,
        where("status", "in", ["PAID", "PENDING", "REFUND", "VOID"]),
        orderBy("createdAt", "desc"),
        limit(20)
      );
      return await getDocs(docsQuery).then((docs) => {
        let invoices = [];
        docs.forEach((doc) => {
          let data = doc.data();
          data["id"] = doc.id;
          // eslint-disable-next-line no-prototype-builtins
          if (data.hasOwnProperty("amount")) {
            data["cost"] = data.amount.total / 100;
          }
          data["createdAt"] = moment(data["createdAt"].toDate()).format(
            "DD/MM/YYYY HH:mm"
          );
          invoices.push(data);
        });
        return invoices;
      });
    },
    // eslint-disable-next-line no-unused-vars
    async fetchRemoteConfig({ commit }, data) {
      let remoteConfig = getRemoteConfig();
      remoteConfig.settings.minimumFetchIntervalMillis = 60000;
      return await fetchAndActivate(remoteConfig)
        .then(() => {
          let value = getValue(remoteConfig, data.value);
          return JSON.parse(value.asString());
        })
        .catch((err) => {
          console.error("fetchAndActivate", err);
        });
    },
    async fetchBookingSettings({ commit }, data) {
      let companyId;
      // eslint-disable-next-line no-prototype-builtins
      if (data && data.hasOwnProperty("id")) {
        companyId = data.id;
      } else {
        companyId = this.state.user.claims.companyId;
      }
      console.log("cmpmmppmpmpmpmppm=====", data);
      let bookingSettingsDoc = getDocReference(
        "companies/" + companyId + "/companyPrivate",
        "bookingSettings"
      );
      return await getDoc(bookingSettingsDoc).then((doc) => {
        if (doc) {
          let bookingSettings = doc.data();
          // eslint-disable-next-line no-prototype-builtins
          if (data && data.hasOwnProperty("id")) {
            return bookingSettings;
          } else {
            commit("GET_BOOKING_SETTINGS", bookingSettings);
            return bookingSettings;
          }

          // console.log('setting User')
        }
      });
    },
    async fetchUserPrivate({ commit }) {
      let companyId = this.state.user.claims.companyId;
      let userPrivateQuery = getDocReference(
        "companies/" +
          companyId +
          "/employees/" +
          this.state.user.claims.user_id +
          "/employeePrivate",
        "info"
      );

      return await getDoc(userPrivateQuery).then((doc) => {
        console.log(
          "userPrivateQueryuserPrivateQueryuserPrivateQuery",
          doc.data()
        );
        if (doc.exists()) {
          let employee = doc.data();
          console.log("user private", employee);
          commit("SET_USER_PRIVATE", employee);
          return employee;
        }
      });
    },
    async fetchSystemUsers({ commit }) {
      let fireS = getFirestore(fireApp);

      let collectionRef = collection(fireS, "systemUsers");
      const docsQuery = query(
        collectionRef,
        where("status", "in", ["ACTIVE", "INACTIVE"]),
        limit(20)
      );
      return await getDocs(docsQuery).then((docs) => {
        let docsData = [];
        docs.forEach((doc) => {
          let data = doc.data();
          data["id"] = doc.id;
          data["name"] = data["firstName"] + " " + data["lastName"];
          docsData.push(data);
        });
        commit("SET_SYSTEM_USERS", docsData);
        return docsData;
      });
    },
    async fetchCompanies({ commit }) {
      let fireS = getFirestore(fireApp);

      let collectionRef = collection(fireS, "companies");
      const docsQuery = query(
        collectionRef,
        where("status", "in", ["ACTIVE", "INACTIVE"]),
        limit(20)
      );

      return await getDocs(docsQuery).then((docs) => {
        let docsData = [];
        docs.forEach((doc) => {
          let data = doc.data();
          data["id"] = doc.id;
          data["createdAt"] = moment(data["createdAt"].toDate()).format(
            "DD/MM/YYYY HH:mm"
          );
          docsData.push(data);
        });
        commit("SET_COMPANIES", docsData);
        return docsData;
      });
    },
    // eslint-disable-next-line no-unused-vars
    async fetchClient({ dispatch, commit }, data) {
      // eslint-disable-next-line no-unreachable
      let path = "companies/" + data.companyId + "/clients";
      let userQuery = getDocReference(path, data.userId);
      getDoc(userQuery).then((doc) => {
        if (doc.exists()) {
          let client = doc.data();
          console.log("client", client);
          commit("SET_CLIENT", client);
          return client;
        }
      });
    },
    async fetchUser({ dispatch, commit }, user) {
      //console.log('user', user)
      if (user) {
        var claims = await dispatch("getClaims");

        console.log("claims", claims);

        if (!["OWNER", "SYSTEM_ADMIN"].includes(claims.role)) {
          dispatch("signOut");
        }
        let path;
        if (claims.role === "SYSTEM_ADMIN") {
          path = "systemUsers/";
        } else {
          path = "companies/" + claims.companyId + "/employees";
        }

        let userQuery = getDocReference(path, user.uid);

        getDoc(userQuery).then((doc) => {
          if (doc.exists()) {
            let employee = doc.data();
            commit("SET_USER", employee);
          }
        });
        commit("SET_USER_CLAIMS", claims);
        if (claims.role !== "SYSTEM_ADMIN") {
          dispatch("fetchCompany");
        } else {
          dispatch("fetchSystemStatistics");
        }
      } else {
        commit("SET_USER", null);
      }
    },
  },
  modules: {},
});
