<template>
  <div>
    <base-header type="gradient-success" class="pb-6 pb-8 pt-5 pt-md-8">
      <!-- Card stats -->
    </base-header>

    <div class="container-fluid mt--7">
      <div class="col-xl-8 order-xl-1">
        <card shadow type="secondary">
          <template v-slot:header>
            <div class="bg-white border-0">
              <div class="row align-items-center">
                <div class="col-8">
                  <h3 class="mb-0">Feature Ideas form</h3>
                </div>
                <div class="col-4 text-right">
                  <base-button @click="clear()" class="btn btn-sm btn-outline-success">Clear</base-button>
                </div>
              </div>
            </div>
          </template>

          <form>
            <h4 class="text-muted mb-4">
              Please share your thoughts, ideas, suggestions. <br>
              Anything that can help us make our platform more convenient to you!
            </h4>
            <div class="">
              <div class="row">
                <div class="col-lg-6">
                  <base-input
                    alternative=""
                    label="Subject"
                    placeholder="Subject"
                    input-classes="form-control-alternative"
                    v-model="model.name"
                  />
                </div>
              </div>
              <div class="row">
                <div class="col-lg-12">
                  <base-input alternative="" label="Description">
                    <textarea
                      rows="4"
                      class="form-control form-control-alternative"
                      placeholder="Just tell us what you need"
                      v-model="model.description"
                    >
                    </textarea>
                  </base-input>
                </div>
              </div>
            </div>
            <div class="text-right">
              <submit-button @clicked="sendEmail" :alertMessage="errorMessage" :state="loadingProcess">
                Submit
              </submit-button>
              <!--              <base-button @click="logIn" type="primary" class="my-4">Sign in</base-button>-->
            </div>
          </form>
        </card>
      </div>
    </div>
  </div>
</template>
<script>
import FirebaseFunctionsHelper from "../plugins/firebaseFunctionsHelper";
import BaseButton from "../components/BaseButton";

export default {
  name: "feature-ideas",
  components: { BaseButton },
  data() {
    return {
      loadingProcess: "",
      errorMessage: "",
      model: {
        description: "",
        name: "",
      },
    };
  },
  methods: {
    clear() {
      this.loadingProcess = "";
      this.errorMessage = "";
      this.model.description = "";
      this.model.name = "";
    },
    generateContent() {
      return (
        "<b>User:</b> " +
        this.$store.state.user.data.firstName +
        " " +
        this.$store.state.user.data.lastName +
        "<br>" +
        "<b>Company:</b> " +
        this.$store.state.company.companyName +
        "<br>" +
        "<b>Title:</b> " +
        this.model.name +
        "<br>" +
        "<b>Description:</b> " +
        this.model.description
      );
    },
    async sendEmail() {
      let self = this;
      this.loadingProcess = "processing";

      // eslint-disable-next-line no-unreachable
      await FirebaseFunctionsHelper.callFunction("sendEmail", {
        content: self.generateContent(),
        sendTo: "admin@pinenative.com",
        title: "Feature Ideas - " + self.model.name,
      })
        .then((data) => {
          if (data) {
            this.loadingProcess = "done";
          } else {
            this.loadingProcess = "failed";
            this.errorMessage = this.$t("There was an error!");
          }
        })
        .catch((error) => {
          this.loadingProcess = "failed";
          this.errorMessage = error.message;
          console.error(error);
        });
    },
  }
};
</script>
<style></style>
