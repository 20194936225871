export function getStatusClass(val) {
  // type (primary|info|danger|default|warning|success)
  switch (val) {
    case "VERIFIED":
    case "PAID":
      return "success";
    case "REFUND":
      return "yellow";
    case "PENDING":
      return "warning";
    case "VOID":
      return "gray";
    default:
      return "red";
  }
}
