<template>
  <section class="section-hero section-shaped my-0">
    <div class="shape shape-style-1 shape-primary">
      <span class="span-150"></span>
      <span class="span-50"></span>
      <span class="span-50"></span>
      <span class="span-75"></span>
      <span class="span-100"></span>
      <span class="span-75"></span>
      <span class="span-50"></span>
      <span class="span-100"></span>
      <span class="span-50"></span>
      <span class="span-100"></span>
    </div>
    <div class="container shape-container d-flex align-items-center">
      <div class="col px-0">
        <div class="row align-items-center">
          <div class="col-sm-12 col-md-8 hero-text pt-lg pt-2">
            <h1 class="text-white big-title">
              Welcome to  <span style="color: whitesmoke;">AppointMe</span> for Partners
            </h1>
            <h2 class="lead text-white mt-4 mb-5 " style="font-size: 27px">
              It's an all-in-one System for everything you need whether you are
              a company or an individual trying to find a way to manage your
              appointments.
            </h2>
            <div class="btn-wrapper pb-4">
              <a href="https://apps.apple.com/cy/app/appointme-partner/id1588625604">
                <img
                  width="160"
                  src="@/assets/img/download-app-icons/app-store.png"
                />
              </a>
              <a href="https://play.google.com/store/apps/details?id=net.appointme.partner" >
                <img
                  width="160"
                  src="@/assets/img/download-app-icons/play-store.png"
                />
              </a>
            </div>
          </div>
        </div>
        <!--                <div class="row align-items-center justify-content-around stars-and-coded">-->
        <!--                    <div class="col-sm-4">-->
        <!--                        <span class="text-white alpha-7 ml-3">Star us on</span>-->
        <!--                        <a href="https://github.com/creativetimofficial/argon-design-system" target="_blank" title="Support us on Github">-->
        <!--                            <img src="img/brand/github-white-slim.png" style="height: 22px; margin-top: -3px">-->
        <!--                        </a>-->
        <!--                    </div>-->
        <!--                    <div class="col-sm-4 mt-4 mt-sm-0 text-right">-->
        <!--                        <span class="text-white alpha-7">Coded by</span>-->
        <!--                        <a href="https://www.creative-tim.com" target="_blank" title="Creative Tim - Premium Bootstrap Themes and Templates">-->
        <!--                            <img src="img/brand/creativetim-white-slim.png" class="ml-3" style="height: 30px;">-->
        <!--                        </a>-->
        <!--                    </div>-->
        <!--                </div>-->
      </div>
    </div>
  </section>
</template>
<script>
export default {};
</script>
<style></style>
