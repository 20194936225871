<template>
  <div>
    <div id="download-apps" class="row" style="width: 100%; margin: 0">
      <div class="col-12 p-2" style="margin: 0 auto; text-align: center">
        <h1
          class="title text-message pt-3"
        >
          Download our mobile apps
        </h1>
      </div>

      <div class="col-12 col-md-9 productBoxSpace">
        <div class="row productBox right align-items-center">
          <div class="softSphere"></div>
          <div class="col-12 col-md-6">
            <div class="_31lev1">
              <h1 class="mainColor">AppointMe Partner</h1>
              <h3 class="title2">Business app for<br />professionals</h3>
            </div>
            <div class="row">
              <a
                class="col-12 col-md-6 btn"
                href="https://apps.apple.com/cy/app/appointme-partner/id1588625604"
                target="_blank"
                rel="noopener noreferrer"
                title="Download App For Business from Apple Store"
              >
                <img
                  width="180"
                  src="@/assets/img/download-app-icons/app-store.png"
                />
              </a>
              <a
                class="col-12 col-md-6 btn"
                href="https://play.google.com/store/apps/details?id=net.appointme.partner"
                target="_blank"
                rel="noopener noreferrer"
                title="Download AppointMe App For Business from Google Play"
              >
                <img
                  width="180"
                  src="@/assets/img/download-app-icons/play-store.png"
                />
              </a>
            </div>
          </div>
          <div class="col-12 col-md-6" style="text-align: center">
            <img
              class="productBox-logo"
              style="width: 100%"
              src="../assets/img/amappointmePartner.png"
            />
          </div>
        </div>
        <div class="row productBox left align-items-center">
          <div class="softSphere"></div>

          <div class="col-12 col-md-6">
            <div class="_31lev1">
              <h1 class="mainColor">AppointMe</h1>
              <h3 class="title2">Booking app for<br />customers</h3>
              <h3 style="color: transparent" class="hideOn768">fill</h3>
              <h3 style="color: transparent" class="hideOn768">fill</h3>
              <h3 style="color: transparent" class="hideOn768">fill</h3>
              <h3 style="color: transparent" class="hideOn768">fill</h3>
              <h3 style="color: transparent" class="hideOn768">fill</h3>
              <h3 style="color: transparent" class="hideOn768">fill</h3>

            </div>
            <div class="row">

              <!--              <a class="col-12 col-md-6 btn" href="https://apps.apple.com/us/app/5346253" target="_blank" rel="noopener noreferrer" title="Download Fresha App For Business from Apple Store">-->
              <!--                <img width="180" src="@/assets/img/download-app-icons/app-store.png" />-->
              <!--              </a>-->
              <!--              <a class="col-12 col-md-6 btn" href="https://play.google.com/store/apps/details?id=com.fresha.Business&amp;hl=en" target="_blank" rel="noopener noreferrer" title="Download Fresha App For Business from Google Play">-->
              <!--                <img width="180" src="@/assets/img/download-app-icons/play-store.png" />-->
              <!--              </a>            -->
            </div>
          </div>
          <div class="col-12 col-md-6" style="text-align: center">
            <img
              class="productBox-logo"
              style="width: 100%"
              src="../assets/img/appointmeClientlogocommingsoon.png"
            />
          </div>
        </div>
      </div>
    </div>
    <br />
  </div>
</template>

<script>
export default {
  name: "download-apps",
};
</script>

<style scoped>
.title {
  line-height: 65px;
  letter-spacing: 0.5px;
  color: teal;
  display: block;
  margin-top: 0;
  font-weight: 600;
  margin-bottom: 24px;
}

.title2 {
  font-size: 25px;
  letter-spacing: 0.5px;
  color: white;

  display: block;
  margin-top: 0;
  font-family: Boing, Helvetica Neue, Helvetica, Arial, sans-serif;
  font-weight: 600;
  margin-bottom: 24px;
}

.productBox {
  background-color: #101928;
  position: relative;
  overflow: hidden;
  height: 390px;
  padding: 32px 32px 237px;
  border-radius: 16px;
  max-width: 500px;
  width: 100%;

  flex: 1 1 50%;
  padding: 46px 32px 260px;
}

@media (min-width: 768px) {
  .productBox {
    flex: 1 1 50%;
    padding: 46px 32px 260px;
    max-width: none;
  }

}

.productBox.right {
  margin-right: 0;
}

.productBox.left {
  margin-left: 0;
  background-color: teal;
}

.productBoxSpace {
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  gap: 16px;
  justify-content: space-between;
}

@media (min-width: 768px) {
  .productBoxSpace {
    flex-direction: row;
    padding: 0;
  }
}

@media (max-width: 668px) {
  .productBox {
    padding: 40px 10px 50px !important;
  }
  ._31lev1 {
    text-align: center;
  }

  .productBox.left {
    margin-left: unset;
    margin: 0 auto;
  }
  .productBox.right {
    margin: 0 auto;
  }

  .productBox-logo{
    width: 250px !important;
    padding: 50px !important;
  }
}
.productBox.right .mainColor {
  color: teal;
}
.link {
  color: white;
}
._1hisuE {
  flex-direction: row;
  justify-content: space-between;
  max-width: 280px;
  margin: auto;
}

.align-items-center {
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
}

.productBox.right .softSphere {
  position: absolute;
  bottom: -470px;
  background: #fff;
  width: 686px;
  height: 686px;
  border-radius: 50%;
  opacity: 0.1;
  right: -323px;
}

.productBox.left .softSphere {
  position: absolute;
  bottom: -470px;
  background: #fff;
  width: 686px;
  height: 686px;
  border-radius: 50%;
  opacity: 0.1;
  left: -323px;
}
</style>
