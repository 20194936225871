<template>
  <div class="row justify-content-center">
    <div class="col-lg-5 col-md-7">
      <div class="header-body text-center mb-5">
        <h1 class="text-white">Welcome Admin!</h1>
        <p class="text-lead text-white">
          If you are not a System Admin and you are lost please go
          <a href="#login">here</a>.
        </p>
      </div>
      <div class="card bg-secondary shadow border-0">
        <div class="card-body px-lg-5 py-lg-5">
          <div class="text-center text-muted mb-4">
            <h2>Admin Email Login</h2>
          </div>
          <form role="form">
            <base-input
              formClasses="input-group-alternative mb-3"
              placeholder="Email"
              addon-left-icon="ni ni-email-83"
              v-model="model.email"
            >
            </base-input>

            <base-input
              formClasses="input-group-alternative mb-3"
              placeholder="Password"
              type="password"
              addon-left-icon="ni ni-lock-circle-open"
              v-model="model.password"
            >
            </base-input>

            <div class="text-center">
              <submit-button
                @clicked="logIn"
                :alertMessage="errorMessage"
                :state="loadingProcess"
                >Submit</submit-button
              >
              <!--              <base-button @click="logIn" type="primary" class="my-4">Sign in</base-button>-->
            </div>
          </form>
        </div>
      </div>
      <div class="row mt-3">
        <div class="col-6">
          <a href="#" class="text-light"><small>Forgot password?</small></a>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
export default {
  name: "login",
  data() {
    return {
      errorMessage: "",
      loadingProcess: "",
      model: {
        email: "",
        password: "",
      },
    };
  },
  methods: {
    logIn() {
      this.loadingProcess = "processing";
      const auth = getAuth(this.fireApp);
      signInWithEmailAndPassword(auth, this.model.email, this.model.password)
        .then((userCredential) => {
          this.loadingProcess = "done";
          // Signed in
          const user = userCredential.user;
          console.log("user", user);
          // ...
        })
        .catch((error) => {
          this.loadingProcess = "failed";
          const errorCode = error.code;
          const errorMessage = error.message;
          this.errorMessage = error.message;
          console.log(errorCode, errorMessage);
        });
    },
  },
};
</script>
<style></style>
