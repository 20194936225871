<template>
  <div>
    <base-header
      class="header pb-8 pt-7 pt-lg-8 d-flex align-items-center"
      style="
        min-height: 200px;
        background-image: url(img/theme/profile-cover.jpg);
        background-size: cover;
        background-position: center top;
      "
    >
      <!-- Mask -->
      <span class="mask bg-gradient-success opacity-8"></span>
      <!-- Header container -->
      <div class="container-fluid d-flex align-items-center justify-content-md-center">
        <div class="row ">
          <div class="col-12">
            <h1 class="display-2 text-white text-center">Contact Us</h1>
            <!--            <p class="text-white mt-0 mb-5">-->
            <!--              This is your profile page. You can see the progress you've made-->
            <!--              with your work and manage your projects or assigned tasks-->
            <!--            </p>-->
            <!--            <a href="#!" class="btn btn-info">Edit profile</a>-->
          </div>
        </div>
      </div>
    </base-header>

    <div class="container-fluid mt--7 ">
      <div class="row justify-content-md-center">
        <div class="col-xl-8 ">
          <card shadow type="secondary">
            <template v-slot:header>
              <div class="bg-white border-0">
                <div class="row align-items-center">
                  <div class="col-8">
                    <h3 class="mb-0 text-muted">We would love to here from you.</h3>
                  </div>
                  <div class="col-4 text-right">
                    <base-button @click="clear()" class="btn btn-sm btn-outline-success">Clear</base-button>
                  </div>
                </div>
              </div>
            </template>
            <template v-if="loadingProcess !== 'done'">
            <form>
              <div class="">
                <div class="row">

                    <div class="col-lg-6">
                      <base-input
                        alternative=""
                        label="Name"
                        placeholder="Name"
                        input-classes="form-control-alternative"
                        v-model="model.name"
                        :valid="v$.model.name.required.$response"
                        :error="v$.model.name.$errors.map( err => { return err.$message }).join()"
                      />
                    </div>
                  <div class="col-lg-6">
                    <base-input
                      alternative=""
                      label="Email address"
                      placeholder="Email address"
                      type="email"
                      required
                      input-classes="form-control-alternative"
                      v-model="model.email"
                      :valid="v$.model.email.email.$response && v$.model.email.required.$response"
                      :error="v$.model.email.$errors.map( err => { return err.$message }).join()"
                    />
                  </div>
                </div>
                <div class="row">
                  <div class="col-lg-12">
                    <base-input alternative="" label="Description"
                                :valid="v$.model.description.required.$response"
                                :error="v$.model.description.$errors.map( err => { return err.$message }).join()">
                    <textarea
                      rows="4"
                      class="form-control form-control-alternative"
                      placeholder="Description"
                      v-model="model.description"
                    >
                    </textarea>
                    </base-input>
                  </div>
                </div>
              </div>
              <div class="text-right">
                <submit-button @clicked="sendEmail" :alertMessage="errorMessage" :state="loadingProcess">
                  Submit
                </submit-button>
                <!--              <base-button @click="logIn" type="primary" class="my-4">Sign in</base-button>-->
              </div>
            </form>
            </template>
            <template v-else>
              <h3>Thank you for your message.</h3>
              <base-button type="primary" tag="a" href="/" class="mt-4">Return home</base-button>
            </template>
          </card>
        </div>
      </div>

    </div>
    </div>

</template>

<script>
import FirebaseFunctionsHelper from "../plugins/firebaseFunctionsHelper";
import useVuelidate from "@vuelidate/core";
import { email, required } from "@vuelidate/validators";
import BaseButton from "../components/BaseButton";

export default {
  name: "ContactUs",
  components: { BaseButton },
  data() {
    return {
      loadingProcess: "",
      errorMessage: "",
      model: {
        email: "",
        description: "",
        name: "",
      },
    };
  },
  setup: () => ({ v$: useVuelidate({ $lazy: true, $autoDirty: true }) }),

  validations() {
    return {
      model: {
        name: {
          required,
        },
        email: {
          email,
          required,
        },
        description: {
          required,
        },
      }
    }
  },
  methods: {
    clear() {
      this.loadingProcess = "";
      this.errorMessage = "";
      this.model.description = "";
      this.model.name = "";
      this.model.email = "";
      this.v$.model.$reset();
    },
    generateContent() {
      return (
        "<b>Name:</b> " +
        this.model.name +
        "<br>" +
        "<b>Email:</b> " +
        this.model.email +
        "<br>" +
        "<b>Description:</b> " +
        this.model.description
      );
    },
    async sendEmail() {
      this.v$.$touch();

      if (this.v$.model.$invalid) {
        console.log(this.v$.$errors);
        return;
      }

      let self = this;
      this.loadingProcess = "processing";

      // eslint-disable-next-line no-unreachable
      await FirebaseFunctionsHelper.callFunction("sendEmail", {
        content: self.generateContent(),
        sendTo: "admin@pinenative.com",
        title: "Contact us - " + self.model.name,
      })
        .then((data) => {
          if (data) {
            this.loadingProcess = "done";
            this.clear()
          } else {
            this.loadingProcess = "failed";
            this.errorMessage = this.$t("There was an error!");
          }
        })
        .catch((error) => {
          this.loadingProcess = "failed";
          this.errorMessage = error.message;
          console.error(error);
        });
    },
  },
};
</script>

<style scoped>

</style>