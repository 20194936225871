<template>
  <div>
    <base-header
      class="header pb-8 pt-5 pt-lg-6 d-flex align-items-center"
      style="
        min-height: 200px;
        background-image: url(img/theme/profile-cover.jpg);
        background-size: cover;
        background-position: center top;
      "
    >
      <!-- Mask -->
      <span class="mask bg-gradient-success opacity-8"></span>
      <!-- Header container -->
      <div class="container-fluid d-flex align-items-center">
        <div class="row">
          <div class="col-12">
            <h1 class="display-2 text-white">Hello {{ model.firstName }}</h1>
            <!--            <p class="text-white mt-0 mb-5">-->
            <!--              This is your profile page. You can see the progress you've made-->
            <!--              with your work and manage your projects or assigned tasks-->
            <!--            </p>-->
            <!--            <a href="#!" class="btn btn-info">Edit profile</a>-->
          </div>
        </div>
      </div>
    </base-header>

    <div class="container-fluid mt--7">
      <div class="row pb-7">
        <div class="col-xl-4 order-xl-2 mb-5 mb-xl-0">
          <div class="card card-profile shadow">
            <div class="row justify-content-center">
              <div class="col-lg-3 order-lg-2">
                <div class="card-profile-image">
                  <a href="#">
                    <img :src="model.imageUrl" class="rounded-circle" />
                  </a>
                </div>
              </div>
            </div>
            <div
              class="card-header text-center border-0 pt-8 pt-md-4 pb-0 pb-md-4"
            >
              <div class="d-flex justify-content-between">
<!--                <base-button size="sm" type="info" class="mr-4"-->
<!--                  >Share</base-button-->
<!--                >-->
                <!--                <base-button size="sm" type="default" class="float-right"-->
                <!--                  >Contact Us</base-button-->
                <!--                >-->
              </div>
            </div>
            <div class="card-body pt-0 pt-md-4">
              <div class="row">
                <div class="col">
                  <div
                    class="card-profile-stats d-flex justify-content-center mt-md-5"
                  ></div>
                </div>
              </div>
              <div class="text-center">
                <h3>
                  {{ model.firstName }} {{ model.lastName }}<span class="font-weight-light"></span>
                </h3>
                <h5>Member since {{ modelPrivate.createdAt }}</h5>
                <div class="h5 mt-4">
                  <i class="ni business_briefcase-24 mr-2"></i
                  >{{ modelPrivate.role }}
                </div>
                <div><i class="ni education_hat mr-2"></i>{{ model.name }}</div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-xl-8 order-xl-1">
          <card shadow type="secondary">
            <template v-slot:header>
              <div class="bg-white border-0">
                <div class="row align-items-center">
                  <div class="col-8">
                    <h3 class="mb-0">Personal Details</h3>
                  </div>
                  <div class="col-4 text-right">
                    <a href="#!" class="btn btn-sm btn-primary">Refresh</a>
                  </div>
                </div>
              </div>
            </template>

            <form>
              <h6 class="heading-small text-muted mb-4">User information</h6>
              <div class="pl-lg-4">
                <div class="row">
                  <div class="col-lg-6">
                    <base-input
                      disabled="true"
                      alternative=""
                      label="Title"
                      placeholder="Title"
                      input-classes="form-control-alternative"
                      v-model="model.title"
                    />
                  </div>
                  <div class="col-lg-6">
                    <base-input
                      disabled="true"
                      alternative=""
                      label="Gender"
                      placeholder="Gender"
                      input-classes="form-control-alternative"
                      v-model="modelPrivate.gender"
                    />
                  </div>
                </div>
                <div class="row">
                  <div class="col-lg-6">
                    <base-input
                      disabled="true"
                      alternative=""
                      label="First name"
                      placeholder="First name"
                      input-classes="form-control-alternative"
                      v-model="model.firstName"
                    />
                  </div>
                  <div class="col-lg-6">
                    <base-input
                      disabled="true"
                      alternative=""
                      label="Last name"
                      placeholder="Last name"
                      input-classes="form-control-alternative"
                      v-model="model.lastName"
                    />
                  </div>
                </div>
                <div class="row">
                  <div class="col-lg-6">
                    <base-input
                      disabled="true"
                      alternative=""
                      label="Date of Birth"
                      placeholder="Date of Birth"
                      input-classes="form-control-alternative"
                      v-model="modelPrivate.dateOfBirth"
                    />
                  </div>
                </div>
                <div class="row">
                  <div class="col-lg-6">
                    <base-input
                      disabled="true"
                      alternative=""
                      label="Contact Number"
                      placeholder="Contact Number"
                      input-classes="form-control-alternative"
                      v-model="model.contactNumber"
                    />
                  </div>
                  <div class="col-lg-6">
                    <base-input
                      disabled="true"
                      alternative=""
                      label="Contact Email"
                      placeholder="Contact Email"
                      input-classes="form-control-alternative"
                      v-model="model.contactEmail"
                    />
                  </div>
                </div>
              </div>

              <hr class="my-4" />
            </form>
          </card>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import moment from "moment";

export default {
  name: "user-profile",
  data() {
    return {
      modelPrivate: {
        createdAt: null,
        dateOfBirth: null,
        gender: "MALE",
        role: "OWNER",
      },
      model: {
        contactEmail: "onid@ddas.sss",
        contactNumber: "+35796375541",
        firstName: "Onisiforos",
        imageUrl: "",
        isOwner: true,
        lastName: "Georgiou",
        name: "The best Hair Saloon",
        status: "ACTIVE",
        title: "The Not Good",
        updatedAt: { seconds: 1628747094, nanoseconds: 582000000 },
        updatedBy: "G2IIP5E63AZZw20o1gatUGEcUql2",
      },
    };
  },
  async mounted() {
    let self = this;
    this.model = this.$store.state.user.data;
    this.modelPrivate = this.$store.state.user.private;
    await this.$store.dispatch("fetchUserPrivate").then((data) => {
      self.modelPrivate = data;
      self.modelPrivate["createdAt"] = moment(
        data["createdAt"].toDate()
      ).format("DD/MM/YYYY");
      self.modelPrivate["dateOfBirth"] = moment(
        data["dateOfBirth"].toDate()
      ).format("DD/MM/YYYY");
    });
  },
};
</script>
<style></style>
