<template>
  <div class="form-group has-label">
    <label class="form-control-label">{{ label }}</label>

    <!--                <base-input-->
    <!--                  alternative=""-->
    <!--                  label="Status"-->
    <!--                  placeholder="Status"-->
    <!--                  input-classes="form-control-alternative"-->
    <!--                  v-model="model.status"-->
    <!--                />-->

    <div
      :style="type != null ? 'display: block;font-size: 12px;' : ''"
      :class="type != null ? 'badge badge-' + type : ''"
      class="text-black-50"
    >
      {{ value }}
    </div>
  </div>
</template>

<script>
export default {
  name: "display-label",
  props: {
    value: {
      type: String,
      default: "",
      description: "the value.",
    },
    label: {
      type: String,
      default: "",
      description: "the label.",
    },
    type: {
      type: String,
      default: null,
      description: "Badge type (primary|info|danger|default|warning|success)",
    },
  },
};
</script>

<style scoped></style>
