<template>
  <card shadow type="secondary">
    <template v-slot:header>
      <div class="bg-white border-0">
        <div class="row align-items-center">
          <div class="col-8">
            <h3 class="mb-0">Company Statistics</h3>
          </div>
          <div class="col-4 text-right">
            <base-button
              type="primary"
              @click="getCompanyStatistics()"
              class="btn btn-sm btn-primary"
              >Refresh</base-button
            >
          </div>
        </div>
      </div>
    </template>

    <form>
      <!--      <h6 class="heading-small text-muted mb-4">Company Details</h6>-->
      <div class="pl-lg-4">
        <div class="row">
          <div class="col-12">
            <display-label
              label="Tokens Balance:"
              :value="model.tokens"
            ></display-label>
          </div>
          <div class="col-12">
            <display-label
              label="Number of clients:"
              :value="model.clientCount"
            ></display-label>
          </div>
          <div class="col-12">
            <display-label
              label="Number of employees:"
              :value="model.employeeCount"
            ></display-label>
          </div>
          <div class="col-12">
            <display-label
              label="Number of services:"
              :value="model.serviceCount"
            ></display-label>
          </div>
        </div>
      </div>
    </form>
  </card>
</template>
<script>
import DisplayLabel from "../../../components/displayLabel";
export default {
  name: "company-statistics-card",
  components: { DisplayLabel },
  data() {
    return {
      alertMessage: "",
      loadingProcess: "",
      model: {
        appointmentTokensLeft: null,
        clientCount: null,
        employeeCount: null,
        serviceCount: null,
        tokens: null,
      },
    };
  },
  mounted() {
    // var self = this;
    console.log(this.$route.params);
    this.getCompanyStatistics();
    // this.mapCompany();
  },
  methods: {
    saveCompany() {
      this.loadingProcess = "processing";
      setTimeout(() => {
        this.loadingProcess = "failed";
        this.alertMessage = "Not implemented yet.";
        //Add Tokens
      }, 2000);
    },
    clearForm() {
      this.loadingProcess = "";
      this.alertMessage = "";
    },
    async getCompanyStatistics() {
      let self = this;
      this.loadingProcess = "processing";
      await this.$store
        .dispatch("fetchCompanyStatistics", {
          companyId: this.$route.params.id,
        })
        .then((data) => {
          this.clearForm();
          console.log("fetchCompanyStatistics", data);
          self.model = data;
        });
    },
  },
};
</script>
<style></style>
