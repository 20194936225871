<template>
  <div>
    <base-header type="gradient-success" class="pb-6 pb-8 pt-5 pt-md-8">
      <!-- Card stats -->
    </base-header>

    <div class="container-fluid mt--7">
      <div class="col-xl-8 order-xl-1">
        <card shadow type="secondary">
          <template v-slot:header>
            <div class="bg-white border-0">
              <div class="row align-items-center">
                <div class="col-8">
                  <h3 class="mb-0">New Purchase</h3>
                </div>
                <!--                  <div class="col-4 text-right">-->
                <!--                    <a href="#!" class="btn btn-sm btn-primary">Settings</a>-->
                <!--                  </div>-->
              </div>
            </div>
          </template>
          <!--          <b-form-select v-model="packageSelected" :options="options"></b-form-select>-->

          <form>
            <!--            <h6 class="heading-small text-muted mb-4">select Package</h6>-->
            <div class="pl-lg-4">
              <div class="row">
                <div
                  class="col-lg-6"
                  v-if="
                    ['SYSTEM_ADMIN'].includes($store.state.user.claims.role)
                  "
                >
                  <div class="form-group has-label">
                    <label class="form-control-label">Select Company</label>
                    <select
                      class="form-control form-control-alternative"
                      name="companyId"
                      id="companyId"
                      v-model="model.companyId"
                    >
                      <option :value="null" selected="selected">
                        Select Company
                      </option>
                      <option
                        :key="company.id"
                        v-for="company in $store.state.system.companies"
                        :value="company.id"
                      >
                        {{ company.companyName }}
                      </option>
                    </select>
                  </div>
                </div>
                <div class="col-lg-6">
                  <div class="form-group has-label">
                    <label class="form-control-label">Select Package</label>
                    <select
                      class="form-control form-control-alternative"
                      name="cars"
                      id="cars"
                      v-model="model.selectedPackage"
                    >
                      <option
                        :key="option.value"
                        v-for="option in options"
                        :value="option.value"
                      >
                        {{ option.text }}
                      </option>
                    </select>
                  </div>
                </div>
                <div class="col-lg-6 pt-2">
                  <br />
                  <base-button
                    @click="makePayment()"
                    :state="paymentProcess"
                    :class="
                      paymentProcess === 'done'
                        ? 'bg-gradient-green border-0'
                        : 'bg-gradient-red border-0'
                    "
                    :disabled="paymentProcess !== ''"
                  >
                    <template v-if="paymentProcess === 'processing'">
                      <span
                        class="fas fa-cog fa-spin"
                        role="status"
                        aria-hidden="true"
                      ></span>
                      Loading...
                    </template>
                    <template v-else-if="paymentProcess === 'done'">
                      <span
                        class="fas fa-money-check"
                        role="status"
                        aria-hidden="true"
                      ></span>
                      Done!
                    </template>
                    <template v-else> Pay by card </template>
                  </base-button>
                </div>
              </div>
              <div class="row">
                <div class="col-lg-12">
                  <base-alert
                    v-if="showError || paymentProcess === 'done'"
                    :type="paymentProcess === 'done' ? 'success' : 'danger'"
                    dismissible
                  >
                    <span class="alert-inner--text">
                      <strong>{{
                        paymentProcess === "done"
                          ? $t("Successful Submission")
                          : $t("Failed Submission")
                      }}</strong
                      ><br />
                      <label class="pl-1">{{ $t(alertMessage) }} </label>
                    </span>
                    <button
                      type="button"
                      class="close"
                      data-dismiss="alert"
                      aria-label="Close"
                    >
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </base-alert>
                </div>
              </div>
            </div>
            <hr class="my-4" />
            <!-- Description -->
          </form>
        </card>
      </div>
    </div>
  </div>
</template>
<script>
import { store } from "../store";
import { loadStripe } from "@stripe/stripe-js";
import { stripePk } from "../main";
export default {
  name: "New Invoice",
  components: {
    // StripeElements,
    // StripeElement,
  },
  data() {
    return {
      alertMessage: "",
      showError: false,
      paymentProcess: "",
      packageSelected: "50",
      availablePackages: {},
      options: [{ value: null, text: "Please select an option" }],
      model: {
        companyId: null,
        selectedPackage: null,
        cost: 20,
        paymentType: "CASH",
        status: "VERIFIED",
        type: "TOKENS",
        tokenCount: null,
      },
    };
  },
  async mounted() {
    console.log("user datattatt", this.$store.state.user)
    await store
      .dispatch("fetchRemoteConfig", { value: "token_packages" })
      .then((data) => {
        console.log("fetchRemoteConfig", data);
        this.availablePackages = data;

        data.forEach((tokenPackage) => {
          let label =
            tokenPackage.label +
            " - " +
            tokenPackage.cost +
            "€ (" +
            tokenPackage.tokens +
            " tokens)";
          this.options.push({ value: tokenPackage["name"], text: label });
        });
      });
  },
  methods: {
    async pay(selectedPackage) {
      const stripe = await loadStripe(stripePk);

      const { error } = await stripe.redirectToCheckout({
        lineItems: [
          {
            price: selectedPackage.priceId,
            quantity: 1,
          },
        ],
        clientReferenceId: this.$store.state.user.claims.companyId,
        mode: "payment",
        successUrl: window.location.origin + "/invoices",
        cancelUrl: window.location.origin + "/invoices",
      });
      console.log("error", error);
    },
    makePayment() {
      let self = this;
      this.showError = false;
      this.alertMessage = "";
      let selectedPackage = this.availablePackages.find(
        (p) => p.name === this.model.selectedPackage
      );
      // console.log('this.model.selectedPackage', this.model.selectedPackage);
      // console.log('selectedPackage', selectedPackage);
      // return;
      // eslint-disable-next-line no-unreachable

      console.log("s", selectedPackage);
      if (selectedPackage) {
        this.model.cost = selectedPackage.cost;
        this.model.tokenCount = selectedPackage.tokens;
      } else {
        this.showError = true;
        this.alertMessage = "Select a package first";
        this.paymentProcess = "";
        return;
      }
      if (this.model.companyId == null) {
        this.model.companyId = this.$store.state.user.claims.companyId;
      }
      console.log("paylll", this.model);

      // eslint-disable-next-line no-unreachable
      this.paymentProcess = "processing";

      self.pay(selectedPackage);
      // FirebaseFunctionsHelper.callFunction(
      //   "addInvoice",
      //   // eslint-disable-next-line no-undef
      //   this.model
      // ).then((result) => {
      //   console.log(result);
      //  self.pay(selectedPackage);
      //   // eslint-disable-next-line no-prototype-builtins
      //   // if (result && result.hasOwnProperty("message")) {
      //   //   this.showError = true;
      //   //   this.alertMessage = result.message;
      //   //   this.paymentProcess = "";
      //   //   return;
      //   // }
      //   // self.paymentProcess = "done";
      //   // self.alertMessage =
      //   //   "The submission was successful. As soon as we receive the payment your invoice will be approved and the token will be credited to your account";
      //   // setTimeout(function () {
      //   //   self.paymentProcess = "";
      //   // }, 2000);
      // });
    },
  },
};
</script>
<style></style>
