<template>
  <!--  <div class="card-blockquote sm">-->
  <!--    <i :class="icon + ' card-img si'"></i>-->
  <!--    <h1>{{ title }}</h1>-->
  <!--    <label>{{ description }}</label>-->
  <!--  </div>-->

  <div :class="classes + ' fixed-width p-4'" style="width: 100%">
    <div
      :class="
        reversed == true
          ? 'col-md-6 col-12 float-right hideOn768'
          : 'col-md-6 col-12 float-left hideOn768'
      "
    >
      <figure class="screen ">
        <kinesis-container event="scroll">
          <kinesis-element
            tag="img"
            :class="imgClasses"
            :src="src"
            :strength="10"
            axis="x"
            type="rotate"
          >
            <i class="fa fa-envelope"></i>
          </kinesis-element>
        </kinesis-container>
      </figure>
    </div>

    <div
      :class="
        reversed == true
          ? 'col-sm-12 col-md-6 col-12 pr-5 pl-7 float-left  p-small-1'
          : 'col-sm-12 col-md-6 col-12 pr-9  float-right  p-small-1'
      "
    >
      <div class="title-secondary text-left pt-5" :class="{ 'pt-5': !reversed }">
        <kinesis-container event="scroll">
          <kinesis-element :strength="5">
            <h4 style="padding-top: 56px; font-size: 32px; font-weight: 600">
              <span
                style="font-family: 'Pacifico', cursive; font-size: 35px"
                :class="isDark ? 'text-white' : 'text-black'"
                >{{ title }}</span
              >
            </h4>
          </kinesis-element>
        </kinesis-container>
      </div>
      <p class="use-text-subtitle2 text-lg">
        <span :class="isDark ? 'text-white' : 'text-black'">{{
          description
        }}</span>
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: "feature",
  props: {
    classes: {
      type: String,
      description: "Background color class",
    },
    imgClasses: {
      type: String,
      default: "",
      description: "Background color class",
    },
    fontColor: {
      type: String,
      description: "font color class",
    },
    isDark: {
      type: Boolean,
      default: false,
    },
    src: {
      type: String,
      description: "Icon for feature",
    },
    reversed: {
      type: Boolean,
      default: false,
      description: "right to left image text",
    },
    title: {
      type: String,
      description: "the title for feature",
    },
    description: {
      type: String,
      description: "description for feature",
    },
  },
};
</script>

<style scoped>
.use-text-subtitle2 {
  font-weight: 400;
  font-size: 22px;
  line-height: 32px;
}
.screen img {
  display: block;
  width: 100%;
  max-width: 400px;
  margin: 0 auto;
}
@media (max-width: 576px) {
  .pl-7,
  .px-7 {
    padding-left: 3rem !important;
  }
  .pr-9,
  .px-7 {
    padding-right: 3rem !important;
  }
}
</style>
