import { createRouter, createWebHistory } from "vue-router";

import DashboardLayout from "@/layout/DashboardLayout";
import AuthLayout from "@/layout/AuthLayout";
import GuestLayout from "@/layout/GuestLayout";

import Dashboard from "../views/Dashboard.vue";
import BillingSettings from "../views/BillingSettings.vue";
import RevenueReports from "../views/RevenueReports.vue";
import Profile from "../views/UserProfile.vue";
import Company from "../views/Company.vue";
import FeatureIdeas from "../views/FeatureIdeas.vue";
import Tables from "../views/Tables.vue";
import Invoices from "../views/Invoices.vue";
import NewInvoice from "../views/NewInvoice.vue";

//System Users Views
import SystemUsers from "../views/System/SystemUsers.vue";
import NewSystemUser from "../views/System/NewSystemUser.vue";
import Companies from "../views/System/Companies.vue";
import SysCompany from "../views/System/SysCompany";
// import SysCompanyAppointments from "../views/System/SysCompanyAppointments";
import ManageClaims from "../views/System/ManageClaims";
import SystemInvoices from "../views/System/Invoices/Invoices";
import SystemInvoice from "../views/System/Invoices/Invoice";
// no auth views
import Login from "../views/Login.vue";
import AdminLogin from "../views/System/AdminLogin.vue";
import Home from "../views/Home.vue";
import ContactUs from "../views/ContactUs";
import AboutUs from "../views/AboutUs";

const routes = [
  {
    path: "/dashboard",
    redirect: "/dashboard",
    meta: { requiresAuth: true },
    component: DashboardLayout,
    children: [
      {
        path: "/system-users",
        name: "System users",
        meta: { role: "SYSTEM_ADMIN" },
        components: { default: SystemUsers },
      },
      {
        path: "/system-users/new",
        name: "new System users",
        meta: { role: "SYSTEM_ADMIN" },
        components: { default: NewSystemUser },
      },
      {
        path: "/companies",
        name: "companies",
        meta: { role: "SYSTEM_ADMIN" },
        components: { default: Companies },
      },
      {
        path: "/manage-claims",
        name: "manage-claims",
        meta: { role: "SYSTEM_ADMIN" },
        components: { default: ManageClaims },
      },
      {
        path: "/system-invoices",
        name: "system-invoices",
        meta: { role: "SYSTEM_ADMIN" },
        components: { default: SystemInvoices },
      },
      {
        path: "/system-invoices/:id",
        name: "system-invoice",
        meta: { role: "SYSTEM_ADMIN" },
        components: { default: SystemInvoice },
      },
      {
        path: "/companies/:id",
        name: "A Company",
        meta: { role: "SYSTEM_ADMIN" },
        component: SysCompany,
      },
      // {
      //   path: "/companies/:id/appointments",
      //   name: "A Company",
      //   meta: { role: "SYSTEM_ADMIN" },
      //   component: SysCompanyAppointments,
      // },
      {
        path: "/dashboard",
        name: "dashboard",
        meta: { role: "OWNER" },
        components: { default: Dashboard },
      },
      {
        path: "/billing-settings",
        name: "billing-settings",
        meta: { role: "OWNER" },
        components: { default: BillingSettings },
      },
      {
        path: "/revenue-reports",
        name: "revenue-reports",
        meta: { role: "OWNER" },
        components: { default: RevenueReports },
      },
      {
        path: "/profile",
        name: "profile",
        meta: { role: "OWNER" },
        components: { default: Profile },
      },
      {
        path: "/company",
        name: "company",
        meta: { role: "OWNER" },
        components: { default: Company },
      },
      {
        path: "/invoices",
        name: "Invoices",
        meta: { role: "OWNER" },
        components: { default: Invoices },
      },
      {
        path: "/invoices/new",
        name: "new invoice",
        components: { default: NewInvoice },
      },
      {
        path: "/feature-ideas",
        name: "feature ideas",
        components: { default: FeatureIdeas },
      },
      {
        path: "/faq",
        name: "faq",
        components: { default: Tables },
      },
      // {
      //   path: "/create-checkout-session",
      //   name: "Checkout",
      //   components: { default: CheckOutStripe },
      // },
    ],
  },
  {
    path: "/",
    redirect: "home",
    component: AuthLayout,
    children: [
      {
        path: "/",
        name: "home",
        components: { default: Home },
      },
      {
        path: "/login",
        name: "login",
        components: { default: Login },
      },
      {
        path: "/admin-login",
        name: "Admin Login",
        components: { default: AdminLogin },
      },
    ],
  },
  {
    path: "/",
    component: GuestLayout,
    children: [
      {
        path: "/contact-us",
        name: "Contact Us",
        components: { default: ContactUs },
      },
      {
        path: "/about-us",
        name: "About Us",
        components: { default: AboutUs },
      },
    ],
  },
];

const router = createRouter({
  history: createWebHistory(),
  linkActiveClass: "active",
  routes,
});

export default router;
