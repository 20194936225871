<template>
  <card shadow type="secondary">
    <template v-slot:header>
      <div class="bg-white border-0">
        <div class="row align-items-center">
          <div class="col-8">
            <h3 class="mb-0">Company Details</h3>
          </div>
          <div class="col-4 text-right">
            <base-button
              type="primary"
              @click="getCompany()"
              class="btn btn-sm btn-primary"
              >Refresh</base-button
            >
          </div>
        </div>
      </div>
    </template>

    <form>
      <h6 class="heading-small text-muted mb-4">Company Details</h6>
      <div class="pl-lg-4">
        <div class="row">
          <div class="col-lg-6">
            <base-input
              :disabled="isDisabled"
              alternative=""
              label="Name"
              placeholder="Name"
              input-classes="form-control-alternative"
              v-model="model.companyName"
            />
          </div>
          <div class="col-lg-6">
            <base-input
              :disabled="isDisabled"
              alternative=""
              label="Status"
              placeholder="Status"
              input-classes="form-control-alternative"
              v-model="model.status"
            />
          </div>
        </div>
        <div class="row">
          <div class="col-lg-6">
            <base-input
              :disabled="isDisabled"
              alternative=""
              label="Contact Phone"
              placeholder="Contact Phone"
              input-classes="form-control-alternative"
              v-model="model.contactPhone"
            />
          </div>
          <div class="col-lg-12">
            <label class="form-control-label">Company Types</label>
            <div class="row col-lg-12 mb-4">
              <badge
                :key="type"
                v-for="type in model.companyTypes"
                pill
                type="info"
                class="m-1"
                >{{ type }}</badge
              >
            </div>
          </div>
          <div class="col-lg-12">
            <base-input alternative="" label="Company Description">
              <textarea
                :disabled="isDisabled"
                rows="4"
                class="form-control form-control-alternative"
                placeholder="A few words about the company ..."
                v-model="model.description"
              >
              </textarea>
            </base-input>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <base-input
              :disabled="isDisabled"
              alternative=""
              label="Street"
              placeholder="Home Address"
              input-classes="form-control-alternative"
              v-model="model.location.street"
            />
          </div>
          <div class="col-md-6">
            <base-input
              :disabled="isDisabled"
              alternative=""
              label="Street 2"
              placeholder="Home Address 2"
              input-classes="form-control-alternative"
              v-model="model.location.street2"
            />
          </div>
        </div>
        <div class="row">
          <div class="col-lg-4">
            <base-input
              :disabled="isDisabled"
              alternative=""
              label="City"
              placeholder="City"
              input-classes="form-control-alternative"
              v-model="model.location.city"
            />
          </div>
          <div class="col-lg-4">
            <base-input
              :disabled="isDisabled"
              alternative=""
              label="Country"
              placeholder="Country"
              input-classes="form-control-alternative"
              v-model="model.location.country"
            />
          </div>
          <div class="col-lg-4">
            <base-input
              :disabled="isDisabled"
              alternative=""
              label="Postal code"
              placeholder="Postal code"
              input-classes="form-control-alternative"
              v-model="model.location.postalCode"
            />
          </div>
        </div>
        <div class="col-12 text-right">
          <base-button @click="clearForm" outline type="info"
            >Reset</base-button
          >
          <submit-button
            @click="saveCompany"
            :alertMessage="alertMessage"
            :state="loadingProcess"
            >Save</submit-button
          >
        </div>
      </div>
    </form>
  </card>
</template>
<script>
export default {
  name: "company-details-card",
  data() {
    return {
      isDisabled: true,
      alertMessage: "",
      loadingProcess: "",
      model: {
        description: "",
        createdAt: null,
        serviceLocation: "",
        serviceGender: {},
        gallery: [],
        autoApprove: true,
        updatedAt: null,
        futureBooking: null,
        companyName: "",
        status: "",
        companyTypes: [],
        contactPhone: "",
        contactEmail: "",
        openingHours: [],
        website: "",
        instagram: "",
        facebook: "",
        updatedBy: "",
        ownerId: "",
        location: {
          street: "",
          country: "",
          city: "",
          coords: { _lat: null, _long: null },
          postalCode: "",
          street2: "",
        },
      },
    };
  },
  props: {
    companyData: {
      type: Object,
      description: "Company data",
    },
  },
  watch: {
    companyData(newVal) {
      this.model = newVal
    },
  },
  mounted() {
    // var self = this;
    console.log(this.$route.params);
    // this.mapCompany();
  },
  methods: {
    saveCompany() {
      this.loadingProcess = "processing";
      setTimeout(() => {
        this.loadingProcess = "failed";
        this.alertMessage = "Not implemented yet.";
        //Add Tokens
      }, 2000);
    },
    clearForm() {
      this.loadingProcess = "";
      this.alertMessage = "";
    },
  },
};
</script>
<style></style>
