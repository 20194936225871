<template>
  <div
    :class="
      ['login', 'Admin Login'].includes($route.name)
        ? 'main-content bg-default'
        : ''
    "
  >
    <hero v-if="!['login', 'Admin Login', 'Contact Us'].includes($route.name)"></hero>
    <base-nav
      class="navbar-top navbar-horizontal navbar-dark"
      containerClasses="px-4 container"
      expand
    >
      <template v-slot="{ closeMenu }">
        <!-- Collapse header -->
        <div class="navbar-collapse-header d-md-none">
          <div class="row">
            <div class="col-6 collapse-brand">
              <router-link to="/">
                <img src="img/brand/green.png" />
              </router-link>
            </div>
            <div class="col-6 collapse-close">
              <button
                type="button"
                @click="closeMenu"
                class="navbar-toggler"
                aria-label="Toggle sidenav"
              >
                <span></span>
                <span></span>
              </button>
            </div>
          </div>
        </div>
        <!-- Navbar items -->
        <ul class="navbar-nav ml-auto">
          <li class="nav-item">
            <a class="nav-link nav-link-icon" target="_blank" href="https://facebook.com/AppointMeCy">
              <i style="font-size: 25px" class="fab fa-facebook-square"></i>
            </a>
          </li>
          <li class="nav-item">
            <a class="nav-link nav-link-icon" target="_blank" href="https://instagram.com/appointmeapp">
              <i style="font-size: 25px" class="fab fa-instagram"></i>
            </a>
          </li>
<!--          <li class="nav-item">-->
<!--            <a class="nav-link nav-link-icon" href="https://teitter.com">-->
<!--              <i style="font-size: 25px" class="fab fa-twitter"></i>-->
<!--            </a>-->
<!--          </li>-->
          <!--          <li class="nav-item">-->
          <!--            <router-link class="nav-link nav-link-icon" to="/">-->
          <!--              <i class="ni ni-planet"></i>-->
          <!--              <span class="nav-link-inner&#45;&#45;text">Home</span>-->
          <!--            </router-link>-->
          <!--          </li>-->
          <!--          <li class="nav-item">-->
          <!--            <router-link class="nav-link nav-link-icon" to="/register">-->
          <!--              <i class="ni ni-circle-08"></i>-->
          <!--              <span class="nav-link-inner--text">Register</span>-->
          <!--            </router-link>-->
          <!--          </li>-->
          <li v-if="$store.state.user.claims == null" class="nav-item">
            <router-link class="nav-link nav-link-icon" to="/login">
              <i class="ni ni-key-25"></i>
              <span class="nav-link-inner--text">Login</span>
            </router-link>
          </li>
          <li
            v-if="
              $store.state.user.claims != null &&
              $store.state.user.claims.role === 'OWNER'
            "
            class="nav-item"
          >
            <router-link class="nav-link nav-link-icon" to="/dashboard">
              <i class="ni ni-single-02" style="font-size: 20px"></i>
              <span class="nav-link-inner--text">Dashboard</span>
            </router-link>
          </li>
          <li
            v-if="
              $store.state.user.claims != null &&
              $store.state.user.claims.role === 'SYSTEM_ADMIN'
            "
            class="nav-item"
          >
            <router-link class="nav-link nav-link-icon" to="/companies">
              <i class="ni ni-single-02"></i>
              <span class="nav-link-inner--text">Dashboard</span>
            </router-link>
          </li>
        </ul>
      </template>
    </base-nav>
    <!-- Navbar -->

    <!--     Header-->
    <div
      v-if="['login', 'Admin Login'].includes($route.name)"
      class="header bg-gradient-success py-7 py-lg-8"
    >
      <div class="separator separator-bottom separator-skew zindex-100">
        <svg
          x="0"
          y="0"
          viewBox="0 0 2560 100"
          preserveAspectRatio="none"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
        >
          <polygon
            class="fill-default"
            points="2560 0 2560 100 0 100"
          ></polygon>
        </svg>
      </div>
    </div>
    <!-- Page content -->
    <div
      :class="
        ['login', 'Admin Login'].includes($route.name)
          ? 'container pt--8 pb-5'
          : ''
      "
    >
      <router-view></router-view>
    </div>
    <footer class="py-5">
      <div class="container">
        <div class="row align-items-center justify-content-xl-between">
          <div class="col-xl-6">
            <div class="copyright text-center text-xl-left text-muted">
              &copy; {{ year }}
<!--              <a-->
<!--                href="https://www.pine-native.com"-->
<!--                class="font-weight-bold ml-1"-->
<!--                target="_blank"-->
<!--                >-->
                PineNative
<!--              </a>-->
            </div>
          </div>
          <div class="col-xl-6">
            <ul
              class="nav nav-footer justify-content-center justify-content-xl-end"
            >
              <li class="nav-item">
                <a
                  href=""
                  class="nav-link"
                  target="_blank"
                  >PineNative</a
                >
              </li>
              <li class="nav-item">
                <a
                  href="/contact-us"
                  class="nav-link"
                  >Contact Us</a>
              </li>
              <li class="nav-item">
                <a
                  href="/about-us"
                  class="nav-link"
                >About Us</a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </footer>
  </div>
</template>
<script>
import Hero from "../views/Components/Hero";
export default {
  name: "auth-layout",
  data() {
    return {
      year: new Date().getFullYear(),
      showMenu: false,
    };
  },
  components: {
    Hero,
  },
  mounted() {
    console.log("name", this.$route.name);
  },
};
</script>
<style></style>
