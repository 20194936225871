<template>
  <div>
    <base-header
      class="header pb-8 pt-7 pt-lg-8 d-flex align-items-center"
      style="
        min-height: 200px;
        background-image: url(img/theme/profile-cover.jpg);
        background-size: cover;
        background-position: center top;
      "
    >
      <!-- Mask -->
      <span class="mask bg-gradient-success opacity-8"></span>
      <!-- Header container -->
      <div class="container-fluid d-flex align-items-center justify-content-md-center">
        <div class="row ">
          <div class="col-12">
            <h1 class="display-2 text-white text-center">About Us</h1>
            <!--            <p class="text-white mt-0 mb-5">-->
            <!--              This is your profile page. You can see the progress you've made-->
            <!--              with your work and manage your projects or assigned tasks-->
            <!--            </p>-->
            <!--            <a href="#!" class="btn btn-info">Edit profile</a>-->
          </div>
        </div>
      </div>
    </base-header>

    <div class="container-fluid mt--7">

      <div class="row justify-content-md-center">
        <div class="col-xl-8 order-xl-1 ">
          <card shadow type="secondary">
            <template v-slot:header>
              <div class="bg-white border-0">
                <div class="row align-items-center">
                  <div class="col-8">
                    <h3 class="mb-0"> Welcome to AppointMe for Partners</h3>
                  </div>
<!--                  <div class="col-4 text-right">-->
<!--                    <a href="#!" class="btn btn-sm btn-primary">Refresh</a>-->
<!--                  </div>-->
                </div>
              </div>
            </template>
            <div class="row ">
              <div class="col-md-8">

                <p>
                  <b>AppointMe</b> is a growing Professional Booking Platform. We are dedicated to provide you the best experience while doing your everyday job, with a focus on your dependability.
                </p>
                <p class="font-weight-bold">
                  Our goal is to achieve the digital transformation of your profession.
                </p>
                <p class="text-success font-weight-bold">
                  We hope you enjoy our services.
                </p>
              </div>
              <div class="col-md-4 text-center hideOn768">
                  <img
                    style="width: 60%"
                    src="../assets/img/amappointmePartner.png"
                  />

              </div>
            </div>
          </card>
        </div>
        </div>
      </div>
    </div>

</template>

<script>
export default {
  name: "ContactUs",
  data() {
    return {
      modelPrivate: {
        createdAt: null,
        dateOfBirth: null,
        gender: "MALE",
        role: "OWNER",
      },
      model: {
        contactEmail: "onid@ddas.sss",
        contactNumber: "+35796375541",
        firstName: "Onisiforos",
        imageUrl: "",
        isOwner: true,
        lastName: "Georgiou",
        name: "The best Hair Saloon",
        status: "ACTIVE",
        title: "The Not Good",
        updatedAt: { seconds: 1628747094, nanoseconds: 582000000 },
        updatedBy: "G2IIP5E63AZZw20o1gatUGEcUql2",
      },
    };
  },
};
</script>

<style scoped>

</style>