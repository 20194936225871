<template>
  <div class="card shadow" :class="type === 'dark' ? 'bg-default' : ''">
    <div
      class="card-header border-0"
      :class="type === 'dark' ? 'bg-transparent' : ''"
    >
      <div class="row align-items-center">
        <div class="col">
          <h3 class="mb-0" :class="type === 'dark' ? 'text-white' : ''">
            {{ title }}
          </h3>
        </div>
        <div class="col text-right">
          <base-button tag="a" href="/system-users/new" type="primary" size="sm"
            >Add New</base-button
          >
        </div>
      </div>
    </div>

    <div class="table-responsive">
      <base-table
        class="table align-items-center table-flush"
        :class="type === 'dark' ? 'table-dark' : ''"
        :thead-classes="type === 'dark' ? 'thead-dark' : 'thead-light'"
        tbody-classes="list"
        :data="tableData"
      >
        <template v-slot:columns>
          <th>Name</th>
          <th>Role</th>
          <th>Status</th>
          <th>Date Created</th>
          <th></th>
        </template>

        <template v-slot:default="row">
          <th scope="row">
            <div class="media align-items-center">
              <div class="media-body">
                <span class="name mb-0 text-sm">{{ row.item.name }}</span>
              </div>
            </div>
          </th>
          <td class="budget">
            {{ row.item.role }}
          </td>
          <td>
            <badge
              class="badge-dot mr-4"
              :type="row.item.status === 'ACTIVE' ? 'success' : 'danger'"
            >
              <i
                :class="`bg-${
                  row.item.status === 'ACTIVE' ? 'success' : 'danger'
                }`"
              ></i>
              <span class="status">{{ row.item.status }}</span>
            </badge>
          </td>
          <td>
            <div class="d-flex align-items-center">
              <!--              <span class="completion mr-2">{{ row.item.completion }}%</span>-->
              <!--              <div>-->
              <!--                <base-progress-->
              <!--                  :type="row.item.statusType"-->
              <!--                  :show-percentage="false"-->
              <!--                  class="pt-0"-->
              <!--                  :value="row.item.completion"-->
              <!--                />-->
              <!--              </div>-->
              <div class="name mb-0 text-md-left">{{ row.item.date }}</div>
            </div>
          </td>

          <td class="text-right">
            <base-button class="bg-gradient-green border-0" size="sm"
              >Edit</base-button
            >
            <base-button
              type="success"
              class="bg-gradient-red border-0"
              size="sm"
              >Delete</base-button
            >
          </td>
        </template>
      </base-table>
    </div>

    <!--    <div-->
    <!--      class="card-footer d-flex justify-content-end"-->
    <!--      :class="type === 'dark' ? 'bg-transparent' : ''"-->
    <!--    >-->
    <!--      <base-pagination total="30"></base-pagination>-->
    <!--    </div>-->
  </div>
</template>
<script>
export default {
  name: "system-users-table",
  props: {
    type: {
      type: String,
    },
    title: String,
  },
  data() {
    return {
      tableData: [],
    };
  },
  mounted() {
    this.getSystemAdmins();
  },
  methods: {
    async getSystemAdmins() {
      await this.$store.dispatch("fetchSystemUsers").then((data) => {
        console.log("fetchSystemUsers", data);
        this.tableData = data;
      });
    },
  },
};
</script>
<style></style>
