<template>
  <div class="card shadow" :class="type === 'dark' ? 'bg-default' : ''">
    <div
      class="card-header border-0"
      :class="type === 'dark' ? 'bg-transparent' : ''"
    >
      <div class="row align-items-center">
        <div class="col">
          <h3 class="mb-0" :class="type === 'dark' ? 'text-white' : ''">
            {{ title }}
          </h3>
        </div>
        <div class="col text-right">
          <base-button @click="refresh()" type="primary" size="sm"
            >Refresh</base-button
          >
          <base-button
            tag="a"
            href="/invoices/new"
            class="bg-gradient-green border-0"
            size="sm"
            >New Purchase</base-button
          >
        </div>
      </div>
    </div>

    <div class="table-responsive">
      <base-table
        type="hover"
        class="table align-items-center table-flush"
        :class="type === 'dark' ? 'table-dark' : ''"
        :thead-classes="type === 'dark' ? 'thead-dark' : 'thead-light'"
        tbody-classes="list"
        :data="invoices"
      >
        <template v-slot:columns>
          <th>Name</th>
          <th>Cost</th>
          <th>Status</th>
          <th>Payment type</th>
          <th>Created At</th>
          <th></th>
        </template>

        <template v-slot:default="row">
          <th scope="row">
            <div class="media align-items-center">
              <div class="media-body">
                <span class="name mb-0 text-sm"
                  >{{
                    Array.isArray(row.item.products)
                      ? String(row.item.products.map((a) => a.name))
                      : ""
                  }}
                </span>
              </div>
            </div>
          </th>
          <td class="budget">€{{ row.item.cost }}</td>
          <td>
            <!--            <display-label label="" :type="getStatusClass(row.item.status)" :value="row.item.status"></display-label>-->
            <badge
              :class="`text-white text-xs mr-4 bg-${getStatusClass(row.item.status)}`"
              :type="getStatusClass(row.item.status)"
            >
<!--              <span style="" :class="`btn-sm p-1 text-white `" class="status">-->
              {{ row.item.status }}
<!--              </span>-->
            </badge>
          </td>
          <td>
            <badge class="mr-4 bg-primary text-white text-xs" type="white">
              {{ row.item.paymentMethod }} -
              {{ row.item.paymentType }}
            </badge>
          </td>
          <td>
            <div class="d-flex align-items-center">
              <!--              <span class="completion mr-2">{{ row.item.completion }}%</span>-->
              <!--              <div>-->
              <!--                <base-progress-->
              <!--                  :type="row.item.statusType"-->
              <!--                  :show-percentage="false"-->
              <!--                  class="pt-0"-->
              <!--                  :value="row.item.completion"-->
              <!--                />-->
              <!--              </div>-->
              <div class="name mb-0 text-md-left">{{ row.item.createdAt }}</div>
            </div>
          </td>

          <td class="text-right">
            <base-button
              @click="downloadInvoice($event, row.item)"
              class="bg-gradient-red border-0"
              size="sm"
              ><i class="fa fa-download"></i> Invoice
            </base-button>
            <!--            <base-button-->
            <!--              tag="a"-->
            <!--              href="/invoices/new"-->
            <!--              type="success"-->
            <!--              class="bg-gradient-green"-->
            <!--              size="sm"-->
            <!--              >Buy again</base-button-->
            <!--            >-->
          </td>
        </template>
      </base-table>
    </div>

    <!--    <div-->
    <!--      class="card-footer d-flex justify-content-end"-->
    <!--      :class="type === 'dark' ? 'bg-transparent' : ''"-->
    <!--    >-->
    <!--      <base-pagination total="30"></base-pagination>-->
    <!--    </div>-->
  </div>
</template>
<script>
import { store } from "../../store";
import { getStatusClass } from "../../directives/helper";
import { appCheck, auth } from "../../main";
import FirebaseFunctionsHelper from "../../plugins/firebaseFunctionsHelper";
const { getToken } = require("firebase/app-check");
export default {
  name: "invoices-table",
  props: {
    type: {
      type: String,
    },
    title: String,
  },
  data() {
    return {
      invoices: [],
      tableData: [],
    };
  },
  mounted() {
    this.getCompanyInvoices();
  },
  methods: {
    async downloadInvoice(event, item) {
      let token = await this.getToken();
      let appCheckTokenResponse = await this.getAppCheckToken();
      console.log("appCheckTokenResponse", appCheckTokenResponse)
      let data = {
        id: item.id
      };
      console.log(data);
      // window.open(
      //   FirebaseFunctionsHelper.getFunctionsUrl("downloadInvoice") +
      //     "?" +
      //     new URLSearchParams(data)
      // );
      // https://europe-west3-appoint-me-233515.cloudfunctions.net/downloadInvoice
      // https://europe-west3-appoint-me-233515.cloudfunctions.net/downloadInvoice
      // FirebaseFunctionsHelper.callFunctionFromURL(data, "downloadInvoice");
      const headers = {
        "Content-Type": "application/json",
        "Authorization": "Bearer " + token,
        "X-Firebase-AppCheck": appCheckTokenResponse.token,
      };
      // eslint-disable-next-line no-unreachable
      event.target.getElementsByTagName("i")[0].className = "fa fa-cog fa-spin";

      this.axios.get(FirebaseFunctionsHelper.getFunctionsUrl("downloadInvoice") +
            "?" +
            new URLSearchParams(data),
          {
            responseType: "blob",
            headers,
          }
        )
        .then((response) => {
          const blob = new Blob([response.data], { type: "application/pdf" });
          const link = document.createElement("a");
          link.href = URL.createObjectURL(blob);
          link.download = "Invoice - " + (item.invoiceRef ?? "");
          link.click();
          URL.revokeObjectURL(link.href);
          event.target.getElementsByTagName("i")[0].className = "fa fa-download";
        })
        .catch((err) => {
          event.target.getElementsByTagName("i")[0].className = "fa fa-download";
          console.error(err);
        });
    },
    async getAppCheckToken() {
      return await getToken(appCheck, false).catch((err) => {
        // Handle any errors if the token was not retrieved.
        console.error(err)
      })
    },
    async getToken() {
      return await auth.currentUser.getIdToken(/* forceRefresh */ true);
    },
    refresh() {
      this.invoices = [];
      this.getCompanyInvoices();
    },
    getStatusClass,
    async getCompanyInvoices() {
      // let self = this;
      await store.dispatch("fetchCompanyInvoices").then((data) => {
        console.log("data", data);
        this.invoices = data;
      });
    },
  },
};
</script>
<style></style>
