<template>
  <div>
    <base-header type="gradient-success" class="pb-6 pb-8 pt-3 pt-md-6">
      <div class="row">
        <div class="col-xl-3 col-lg-6">
          <stats-card
            title="Tokens Left"
            type="gradient-orange"
            :sub-title="companyStatistics.tokens"
            icon="ni ni-chart-pie-35"
            class="mb-4 mb-xl-0"
          >
            <template v-slot:footer>
              <span class="text-success mr-2">
                <base-button
                  class="bg-gradient-red border-0"
                  size="sm"
                  tag="a"
                  href="/invoices/new"
                  >Get More</base-button
                >
              </span>
              <span class="text-nowrap"></span>
            </template>
          </stats-card>
        </div>
        <div class="col-xl-3 col-lg-6">
          <stats-card
            title="Total Clients"
            type="gradient-red"
            :sub-title="companyStatistics.clientCount"
            icon="fa fa-users"
            class="mb-4 mb-xl-0"
          >
            <template v-slot:footer>
              <span class="text-success mr-2"> </span>
              <!--              <span class="text-nowrap">Since last month</span>-->
            </template>
          </stats-card>
        </div>
        <div class="col-xl-3 col-lg-6">
          <stats-card
            :title="graphMonthsLabels.at(-1)"
            type="gradient-green"
            :sub-title="'€' + lastMonthRevenue"
            icon="ni ni-money-coins"
            class="mb-4 mb-xl-0"
          >
            <template v-slot:footer>
              <span
                :class="
                  lastMonthRevenuePercentage >= 0
                    ? 'text-success mr-2'
                    : 'text-danger mr-2'
                "
              >
                <i
                  :class="
                    lastMonthRevenuePercentage >= 0
                      ? 'fa fa-arrow-up'
                      : 'fa fa-arrow-down'
                  "
                ></i>
                {{ lastMonthRevenuePercentage.toFixed(2) }}%
              </span>
              <span class="text-nowrap">Since last Month</span>
            </template>
          </stats-card>
        </div>
        <div class="col-xl-3 col-lg-6">
          <stats-card
            title="Rating"
            type="gradient-info"
            sub-title=""
            sub-title-icon="fa fa-star"
            :sub-title-icon-count="5"
            sub-title-icon-color="gold"
            icon="ni ni-chart-bar-32"
            class="mb-4 mb-xl-0"
          >
            <template v-slot:footer>
              <span class="text-success mr-2"> COMING SOON </span>
            </template>
          </stats-card>
        </div>
      </div>
    </base-header>

    <div class="container-fluid mt--7">
      <!--Charts-->
      <div class="row">
        <div class="col-xl-8 mb-5 mb-xl-0">
          <div class="position-absolute">
            <i class="fas fa-cog fa-spin"></i>
          </div>
          <card type="default" header-classes="bg-transparent">
            <template v-slot:header>
              <div class="row align-items-center">
                <div class="col">
                  <div class="row">
                    <div class="col-6">
                      <h6 class="text-light text-uppercase ls-1 mb-1">Revenue
                      </h6>
                    </div>
                    <div class="col-6">
                      <h6 class="text-light text-uppercase ls-1 mb-1 text-right">
                        Last update: {{ formatExpiryDate }}
                      </h6>
                    </div>
                  </div>

                  <h5 class="h3 text-white mb-0">Last 4 Months</h5>
                  <label class="text-sm">
                    Monthly Revenue is calculated based on the completed appointments of each month.
                  </label>
                </div>
                <!--                <div class="col">-->
                <!--                  <ul class="nav nav-pills justify-content-end">-->
                <!--                    <li class="nav-item mr-2 mr-md-0">-->
                <!--                      <a-->
                <!--                        class="nav-link py-2 px-3"-->
                <!--                        href="#"-->
                <!--                        :class="{ active: bigLineChart.activeIndex === 0 }"-->
                <!--                        @click.prevent="initBigChart(0)"-->
                <!--                      >-->
                <!--                        <span class="d-none d-md-block">Month</span>-->
                <!--                        <span class="d-md-none">M</span>-->
                <!--                      </a>-->
                <!--                    </li>-->
                <!--                    <li class="nav-item">-->
                <!--                      <a-->
                <!--                        class="nav-link py-2 px-3"-->
                <!--                        href="#"-->
                <!--                        :class="{ active: bigLineChart.activeIndex === 1 }"-->
                <!--                        @click.prevent="initBigChart(1)"-->
                <!--                      >-->
                <!--                        <span class="d-none d-md-block">Week</span>-->
                <!--                        <span class="d-md-none">W</span>-->
                <!--                      </a>-->
                <!--                    </li>-->
                <!--                  </ul>-->
                <!--                </div>-->
              </div>
            </template>
            <div class="chart-area">
              <canvas :height="350" :id="salesChartID"></canvas>
            </div>
          </card>
        </div>

        <div class="col-xl-4">
          <card header-classes="bg-transparent">
            <template v-slot:header>
              <div class="row align-items-center">
                <div class="col">
                  <div class="row">
                    <div class="col-6">
                      <h6 class="text-uppercase text-muted ls-1 mb-1">
                        Performance
                      </h6>
                    </div>
                    <div class="col-6">
                      <h6 class="text-muted text-uppercase ls-1 mb-1 text-right">
                        Last update: {{ formatExpiryDate }}
                      </h6>
                    </div>
                  </div>
                  <h5 class="h3 mb-0">Total Appointments</h5>
                </div>
              </div>
            </template>
            <div class="chart-area">
              <canvas :height="385" :id="appointmentsCountChartID"></canvas>
            </div>
          </card>
        </div>
      </div>
      <!-- End charts-->

      <!--Tables-->
      <!--      <div class="row mt-5">-->
      <!--        <div class="col-xl-8 mb-5 mb-xl-0">-->
      <!--          <page-visits-table></page-visits-table>-->
      <!--        </div>-->
      <!--        <div class="col-xl-4">-->
      <!--          <social-traffic-table></social-traffic-table>-->
      <!--        </div>-->
      <!--      </div>-->
      <!--End tables-->
    </div>
  </div>
</template>
<script>
// Charts
import { appointmentsCountChart } from "../components/Charts/Chart";
import ChartJS from "chart.js/auto";
// import PageVisitsTable from "./Dashboard/PageVisitsTable";
// import SocialTrafficTable from "./Dashboard/SocialTrafficTable";
import { store } from "../store";
import moment from "moment";
import FirebaseFunctionsHelper from "../plugins/firebaseFunctionsHelper";
let chart;

export default {
  components: {
    // PageVisitsTable,
    // SocialTrafficTable,
  },
  data() {
    return {
      graphMonths: [],
      lastMonthRevenue: 0,
      lastMonthRevenuePercentage: 0,
      graphMonthsLabels: [],
      companyStatistics: {
        token: null,
        clientCount: null,
        employeeCount: null,
        serviceCount: null,
        tokens: null,
      },
      salesChartID: "salesChart",
      appointmentsCountChartID: "appointmentsCountChartID",
      bigLineChart: {
        allData: [
          [0, 20, 10, 30, 15, 40, 20, 60, 60],
          [0, 20, 5, 35, 10, 30, 15, 40, 40],
        ],
        activeIndex: 0,
      },
    };
  },
  mounted() {
   // ChartJS.defaults.color = '#dedbdb';
    this.getStatistics();
    // this.createBigChart();
    this.getRevenueStatistics();
  },
  computed: {
    formatExpiryDate() {
      return moment(this.$store.state.expiryDate)
        .add(-30, "m")
        .format("DD MMM YYYY HH:mm");
    },
  },
  methods: {
    calculateCountData() {
      let self = this;
      let data = {};
      this.graphMonths.forEach((key) => {
        Object.keys(self.companyMonthlyStatistics).forEach(function(status) {
          let VERIFIEDMonth = self.companyMonthlyStatistics[status].find(m => key === moment(m.month, "yyyy-MM-DD HH:mm:ss Z").format("YYYYMM"))
          if (VERIFIEDMonth) {
            // eslint-disable-next-line no-prototype-builtins
            if (!data.hasOwnProperty(VERIFIEDMonth.status)) {
              data[VERIFIEDMonth.status] = [];
            }
            data[VERIFIEDMonth.status].push(VERIFIEDMonth.appointments);
          }
        });
      });
      return data;
    },
    calculateData(months) {
      let self = this;
      // [0, 20, 10, 30, 15, 40, 20, 60, 60]
      let data = [];
      months.forEach((key) => {
        let theMonth = self.companyMonthlyStatistics["VERIFIED"].find(m => key === moment(m.month, "yyyy-MM-DD HH:mm:ss Z").format("YYYYMM"))
        data.push(theMonth.revenue ?? 0);
      });

      return data;
    },
    createBigChart() {
      console.log("graphMonths", this.graphMonths)
      let data = this.calculateData(this.graphMonths);
      // eslint-disable-next-line no-unused-vars
      chart = new ChartJS(
        document.getElementById(this.salesChartID).getContext("2d"),
        {
          type: "line",
          data: {
            labels: this.graphMonthsLabels,
            datasets: [
              {
                label: "Earnings",
                tension: 0.3,
                borderWidth: 4,
                borderColor: "#30b08a",
                pointRadius: 3,
                backgroundColor: "#30b08a",
                data: data,
              },
            ],
          },
          options: {
            color: "#d3d3d3",
            responsive: true,
            maintainAspectRatio: false,
            // legend: {
            //   display: false,
            // },
            tooltips: {
              enabled: true,
              mode: "index",
              interaction: {
                mode: "index",
              },
              intersect: true,
            },
            scales: {
              x: {
                display: true,
                title: {
                  display: true,
                  text: "Month",
                  color: "#d3d3d3",
                },
                ticks: {
                  color: "#d3d3d3",
                }
              },
              y: {
                display: true,
                title: {
                  display: true,
                  text: "Amount",
                  color: "#d3d3d3",
                },
                ticks: {
                  color: "#d3d3d3",
                }
              }
            }
            // scales: {
            //   yAxes: [
            //     {
            //       barPercentage: 1.6,
            //       gridLines: {
            //         drawBorder: false,
            //         color: "rgba(29,140,248,0.1)",
            //         zeroLineColor: "red",
            //       },
            //       ticks: {
            //         padding: 0,
            //         fontColor: "#8898aa",
            //         fontSize: 13,
            //         fontFamily: "Open Sans, sans-serif",
            //       },
            //     },
            //   ],
            //   xAxes: [
            //     {
            //       barPercentage: 1.6,
            //       gridLines: {
            //         drawBorder: false,
            //         color: "rgba(29,140,248,0.0)",
            //         zeroLineColor: "transparent",
            //       },
            //       ticks: {
            //         padding: 10,
            //         fontColor: "#8898aa",
            //         fontSize: 13,
            //         fontFamily: "Open Sans, sans-serif",
            //       },
            //     },
            //   ],
            // },
            // layout: {
            //   padding: 0,
            // },
          },
        }
      );
    },
    async getStatistics() {
      let self = this;
      await store.dispatch("fetchCompanyStatistics").then((data) => {
        console.log("data", data);
        self.companyStatistics = data;
      });
    },
    getMonths(numb) {
      let labels = [];
      let months = [];
      for (let step = numb - 1; step >= 0; ) {
        let month = moment().subtract(step, "months").format("YYYYMM");
        let monthLabel = moment().subtract(step, "months").format("MMMM");
        step--;
        months.push(month);
        labels.push(monthLabel);
        console.log("months", months);
      }
      return { months: months, labels: labels };
    },
    async getBigQueryData() {
      let self = this;
      console.log("getBigQueryDatagetBigQueryDatagetBigQueryData");
      if (
        !this.$store.getters.isCacheExpired &&
        this.$store.state.companyMonthlyStatistics !== null
      ) {
        console.log("from cache");
        return this.$store.state.companyMonthlyStatistics;
      } else {
        console.log("from funcitons");
        return await FirebaseFunctionsHelper.callFunction(
          "getCompanyStatistics",
          {
            months: 4,
          }
        )
          .then((data) => {
            let groupedData = {};
            data.forEach((month) => {
              // console.log("month", month)
              // eslint-disable-next-line no-prototype-builtins
              if(!groupedData.hasOwnProperty(month.status)){
                groupedData[month.status] = [];
              }

              groupedData[month.status].push(month);
              // if (month.status === "VERIFIED") {
              //   verifiedData.push(month);
              // }
            });
            console.log("verifiedData", groupedData);
            self.$store.dispatch("setCompanyMonthlyStatistics", groupedData)
            return groupedData;
          })
          .catch((error) => {
            console.error(error);
          });
      }
    },
    async getRevenueStatistics() {
      let self = this;
      let results = this.getMonths(4);
      this.graphMonths = results.months;
      this.graphMonthsLabels = results.labels;

      self.companyMonthlyStatistics = await this.getBigQueryData();
      self.createBigChart();
      self.calcLastMonthRevenue();
      appointmentsCountChart.createChart(
        self.appointmentsCountChartID,
        self.graphMonthsLabels,
        self.calculateCountData()
      );
    },
    calcLastMonthRevenue() {
      let lastTowMonths = this.graphMonths.slice(
        Math.max(this.graphMonths.length - 2, 0)
      );
      let data = this.calculateData(lastTowMonths);
      console.log("lastMonthRevenue lastTowMonths", lastTowMonths);
      console.log("lastMonthRevenue data", data);

      this.lastMonthRevenue = data[1];
      this.lastMonthRevenuePercentage =
        ((data[1] - data[0]) / ((data[1] + data[0]) / 2)) * 100;
    },
  },
}
</script>
<style></style>
