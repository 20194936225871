<template>
  <div>
    <base-header type="gradient-success" class="pb-6 pb-8 pt-5 pt-md-8">
      <!-- Card stats -->
    </base-header>

    <div class="container-fluid mt--7">
      <div class="col-xl-8 order-xl-1">
        <card shadow type="secondary">
          <template v-slot:header>
            <div class="bg-white border-0">
              <div class="row align-items-center">
                <div class="col-8">
                  <h3 class="mb-0">Appointment Revenue Reports</h3>
                </div>
<!--                <div class="col-4 text-right">-->
<!--                  <base-button @click="fetchBillingSettings()" class="btn btn-sm btn-outline-success">Refresh</base-button>-->
<!--                </div>-->
              </div>
            </div>
          </template>

          <form>
            <h4 class="text-muted mb-4">
              Here you can download your revenue reports which is based on your appointments <br>
              Submit to download the report.
            </h4>
            <div class="">
              <select
                class="form-control form-control-alternative"
                name="cars"
                id="cars"
                v-model="type"
              >
                <option
                  value=""
                  selected
                >Select an option</option>
                <option
                  :key="typeStr"
                  v-for="typeStr in ['byLastMonths', 'byYear', 'byMonthAndYear']"
                  :value="typeStr"
                >
                  {{ $t(typeStr) }}
                </option>
              </select>
              <div class="pt-3" v-if="type === 'byMonthAndYear'">
<!--                <label class="form-control-label">Get a report for a specific month and year</label>-->
                <div  class="row">
                <div class="col-md-4">
                  <div class="form-group has-label"
                       :class="[{ 'has-danger':  v$.byMonthAndYear.month.$error }]"
                  >
                    <select
                      class="form-control form-control-alternative"
                      name="cars"
                      id="cars"
                      v-model="byMonthAndYear.month"
                    >
                      <option
                        :value="null"
                        selected
                      >Select Month</option>
                      <option
                        :key="index"
                        v-for="(month, index) in months"
                        :value="index"
                      >
                        {{ month }}
                      </option>
                    </select>
                    <label class="ml-1 text-red text-sm" v-if="v$.byMonthAndYear.month.$error">{{v$.byMonthAndYear.month.$errors.map( err => { return err.$message }).join()}}</label>

                  </div>
                </div>
                <div class="col-md-4">
                  <div class="form-group has-label"
                       :class="[{ 'has-danger':  v$.byMonthAndYear.year.$error }]">

                    <select
                      class="form-control form-control-alternative"
                      name="cars"
                      id="cars"
                      v-model="byMonthAndYear.year"
                    >
                      <option
                        :value="null"
                        selected
                      >Select Year</option>
                      <option
                        :key="year"
                        v-for="year in years"
                        :value="year"
                      >
                        {{ year }}
                      </option>
                    </select>
                    <label class="ml-1 text-red text-sm" v-if="v$.byMonthAndYear.year.$error">{{v$.byMonthAndYear.year.$errors.map( err => { return err.$message }).join()}}</label>

                  </div>
                </div>
                <div class="col-md-4">
                  <base-button
                    @click="submitByYearAndMonth($event)"
                    class="bg-gradient-green border-0 p-2 m-1"
                    size="md"
                  ><i class="fa fa-download"></i> Download
                  </base-button>
                </div>

              </div>
              </div>
              <div class="pt-3" v-if="type === 'byYear'">
<!--                <label class="form-control-label">Get a report for a specific Year</label>-->
                <div class="row">

                <div class="col-md-4">

                  <div class="form-group has-label"
                       :class="[{ 'has-danger':  v$.byYear.year.$error }]"
                  >

                    <select
                      class="form-control form-control-alternative"
                      name="cars"
                      id="cars"
                      v-model="byYear.year"
                    >
                      <option
                        :value="null"
                        selected
                      >Select Year</option>
                      <option
                        :key="year"
                        v-for="year in years"
                        :value="year"
                      >
                        {{ year }}
                      </option>
                    </select>
                    <label class="ml-1 text-red text-sm" v-if="v$.byYear.year.$error">{{v$.byYear.year.$errors.map( err => { return err.$message }).join()}}</label>

                  </div>

                </div>
                <div class="col-md-4">
                  <base-button
                    @click="submitByYear($event)"
                    class="bg-gradient-green border-0 p-2 m-1"
                    size="md"
                  ><i class="fa fa-download"></i> Download
                  </base-button>
                </div>

              </div>
              </div>
              <div class="pt-3" v-if="type === 'byLastMonths'">
<!--                <label class="form-control-label">Get a report of last number of months</label>-->
                <div class="row">

                <div class="col-md-3">

                  <div class="form-group has-label"
                       :class="[{ 'has-danger':  v$.byLastMonths.lastMonths.$error }]"
                  >

                    <select
                      class="form-control form-control-alternative"
                      name="cars"
                      id="cars"
                      v-model="byLastMonths.lastMonths"
                    >
                      <option
                        :value="null"
                        selected
                      >Select number</option>
                      <option
                        :key="lastMonths"
                        v-for="lastMonths in [1,2,3,4,5,6,7,8,9,10,11,12]"
                        :value="lastMonths"
                      >
                        {{ lastMonths }}
                      </option>
                    </select>
                    <label class="ml-1 text-red text-sm" v-if="v$.byLastMonths.lastMonths.$error">{{v$.byLastMonths.lastMonths.$errors.map( err => { return err.$message }).join()}}</label>

                  </div>

                </div>
                <div class="col-md-4">
                  <base-button
                    @click="submitByLastMonths($event)"
                    class="bg-gradient-green border-0 p-2 m-1"
                    size="md"
                  ><i class="fa fa-download"></i> Download
                  </base-button>
                </div>
              </div>
              </div>
            </div>
          </form>
        </card>
      </div>
    </div>
  </div>
</template>
<script>
import FirebaseFunctionsHelper from "../plugins/firebaseFunctionsHelper";
import useVuelidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";

import { getToken } from "firebase/app-check";
import { appCheck, auth } from "../main";

export default {
  name: "revenue-reports",
  components: { },
  setup: () => ({ v$: useVuelidate() }),
  validations() {
    return {
      byMonthAndYear: {
        year: {
          required,
        },
        month: {

          required,
        }
      },
      byYear: {
        year: {
          required,
        },
      },
      byLastMonths: {
        lastMonths: {
          required,
        }
      },
    };
  },
  data() {
    return {
      type: "",
      loadingProcess: "",
      errorMessage: "",
      years: [],
      months: [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ],
      byYear: {
        year: null,
      },
      byMonthAndYear: {
        month: null,
        year: null,
      },
      byLastMonths: {
        lastMonths: null,
      },
    };
  },
  mounted() {
    this.years = this.getYears();
  },
  methods: {
    getYears() {
      const currentYear = new Date().getFullYear(), years = [];
      let startYear = 2022;
      while (startYear <= currentYear) {
        years.push(startYear++);
      }
      return years;
    },
    async downloadReport(event, data) {
      let token = await this.getToken();
      let appCheckTokenResponse = await this.getAppCheckToken();

      const headers = {
        "Content-Type": "application/json",
        "Authorization": "Bearer " + token,
        "X-Firebase-AppCheck": appCheckTokenResponse.token,
      };
      // eslint-disable-next-line no-unreachable
      event.target.getElementsByTagName("i")[0].className = "fa fa-cog fa-spin";

      this.axios.get(FirebaseFunctionsHelper.getFunctionsUrl("downloadCompanyRevenueReport") +
        "?" +
        new URLSearchParams(data),
          {
            responseType: "blob",
            headers,
          }
      )
        .then((response) => {
          const blob = new Blob([response.data], { type: "application/pdf" });
          const link = document.createElement("a");
          link.href = URL.createObjectURL(blob);
          link.download = "Report";
          link.click();
          URL.revokeObjectURL(link.href);
          event.target.getElementsByTagName("i")[0].className = "fa fa-download";
        })
        .catch((err) => {
          event.target.getElementsByTagName("i")[0].className = "fa fa-download";
          console.error(err);
        });
    },
    async getAppCheckToken() {
      return await getToken(appCheck, false).catch((err) => {
        // Handle any errors if the token was not retrieved.
        console.error(err)
      })
    },
    async getToken() {
      return await auth.currentUser.getIdToken(/* forceRefresh */ true);
    },
    submitByYear(event) {
      this.v$.byYear.$touch();

      if (this.v$.byYear.$invalid) {
        console.log(this.v$.$errors);
        return;
      }
      this.downloadReport(event, this.byYear);
    },
    submitByYearAndMonth(event) {
      this.v$.byMonthAndYear.$touch();

      if (this.v$.byMonthAndYear.$invalid) {
        console.log(this.v$.$errors);
        return;
      }
      const data = JSON.parse(JSON.stringify(this.byMonthAndYear));
      data.month = this.byMonthAndYear.month + 1;
      this.downloadReport(event, data);
    },
    submitByLastMonths(event) {
      this.v$.byLastMonths.$touch();

      if (this.v$.byLastMonths.$invalid) {
        console.log(this.v$.$errors);
        return;
      }
      this.downloadReport(event, this.byLastMonths)
    },
  }
};
</script>
<style></style>
