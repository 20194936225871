<template>
  <base-nav
    class="navbar-top navbar-dark"
    :show-toggle-button="false"
    type=""
    effect="light"
    expand
  >
    <base-dropdown
      tag="li"
      class="nav-item"
      style="right: 30px; position: absolute"
    >
      <template v-slot:title>
        <div class="media align-items-center">
          <div class="media-body mr-2 d-none d-lg-block">
            <span class="mb-0 text-sm text-white font-weight-bold">{{
              $store.state.user.data == null
                ? ""
                : $store.state.user.data.firstName +
                  " " +
                  $store.state.user.data.lastName
            }}</span>
          </div>
          <span class="avatar avatar-sm rounded-circle mt-2">
            <!--                <img-->
            <!--                  alt="Image placeholder"-->
            <!--                  src="img/theme/team-4-800x800.jpg"-->
            <!--                />-->
            <i class="fa fa-user"></i>
          </span>
        </div>
      </template>

      <router-link to="/profile" class="nav-link dropdown-item"
        ><i class="fa fa-user-circle" style="font-size: 25px"></i
        >Profile</router-link
      >

      <!--          <router-link to="/register" class="dropdown-item">Register</router-link>-->
      <base-button @click="signOut" class="dropdown-item">Logout</base-button>
    </base-dropdown>
  </base-nav>
</template>
<script>
import BaseNav from "../components/BaseNav";
import BaseDropdown from "../components/BaseDropdown";
// import CloseButton from "../components/CloseButton";
export default {
  data() {
    return {
      activeNotifications: false,
      showMenu: false,
      searchQuery: "",
    };
  },
  mounted() {},
  methods: {
    signOut() {
      let res = this.$store.dispatch("signOut");
      console.log(res);
    },
    toggleSidebar() {
      this.$sidebar.displaySidebar(!this.$sidebar.showSidebar);
    },
    hideSidebar() {
      this.$sidebar.displaySidebar(false);
    },
    toggleMenu() {
      this.showMenu = !this.showMenu;
    },
  },
  components: {
    BaseNav,
    // CloseButton,
    BaseDropdown,
  },
};
</script>
