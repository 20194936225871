<template>
  <footer class="footer px-4 pt-3">
    <div class="row align-items-center justify-content-lg-between">
      <div class="col-lg-6">
        <div class="copyright text-center text-lg-left text-muted">
          © {{ year }}
<!--          <a-->
<!--            href="https://www.pine-native.com"-->
<!--            class="font-weight-bold ml-1"-->
<!--            target="_blank"-->
<!--            >-->
            PineNative
<!--          </a>-->
        </div>
      </div>
      <div class="col-lg-6">
        <ul
          class="nav nav-footer justify-content-center justify-content-lg-end"
        >
          <li class="nav-item">
<!--            <a-->
<!--              href="https://www.pine-native.com"-->
<!--              class="nav-link"-->
<!--              target="_blank"-->
<!--              >-->
<!--            Pine Native-->
<!--            </a-->
<!--            >-->
          </li>
          <li class="nav-item">
            <router-link class="nav-link" target="_blank" to="/about-us">
              About Us
            </router-link>
          </li>
          <li class="nav-item">
            <router-link class="nav-link" to="/contact-us">
              Contact Us
            </router-link>
          </li>
<!--          <li class="nav-item">-->
<!--            <a-->
<!--              href="https://www.creative-tim.com/blog"-->
<!--              class="nav-link"-->
<!--              target="_blank"-->
<!--              >Blog</a-->
<!--            >-->
<!--          </li>-->
<!--          <li class="nav-item">-->
<!--            <a-->
<!--              href="https://www.creative-tim.com/license"-->
<!--              class="nav-link"-->
<!--              target="_blank"-->
<!--              >License</a-->
<!--            >-->
<!--          </li>-->
        </ul>

      </div>

    </div>
  </footer>
</template>
<script>
export default {
  data() {
    return {
      year: new Date().getFullYear(),
      version: null,
    };
  },
};
</script>
<style></style>
