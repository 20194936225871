<template>
  <div class="row justify-content-center">
    <div class="col-lg-5 col-md-7 mt-3">
      <div class="card bg-secondary shadow border-0">
        <div class="card-body px-lg-5 py-lg-5">
          <div class="text-center text-muted mb-4 mt-4">
            <h2>Login</h2>
          </div>
          <form role="form">
            <base-input
              formClasses="input-group-alternative mb-3"
              placeholder="Phone Number"
              addon-left-icon="fa fa-phone"
              v-model="phoneNumber"
            />

            <base-input
              formClasses="input-group-alternative mb-3"
              v-if="codeSent"
              placeholder="Verification Code"
              addon-left-icon="fa fa-key"
              v-model="code"
            >
            </base-input>
            <base-alert v-if="showAlert" :type="alertType" dismissible>
              <strong></strong> {{ alertMessage }}
              <button
                type="button"
                class="close"
                data-dismiss="alert"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </base-alert>
            <!--            <base-checkbox class="custom-control-alternative">-->
            <!--              <span class="text-muted">Remember me</span>-->
            <!--            </base-checkbox>-->
            <div class="text-center">
              <base-button
                v-if="!codeSent"
                @click="getCode"
                :disabled="sendingCodeStatus"
                type="primary"
              >
                <template v-if="sendingCodeStatus">
                  <span
                    class="fas fa-cog fa-spin"
                    role="status"
                    aria-hidden="true"
                  ></span>
                </template>
                <template v-else> Get Code </template>
              </base-button>
              <base-button
                v-else
                type="primary"
                @click="signIn"
                :disabled="code.length !== 6 || signInLoader"
                class="mt-4 mb-2"
              >
                <template v-if="signInLoader">
                  <span
                    class="fas fa-cog fa-spin"
                    role="status"
                    aria-hidden="true"
                  ></span>
                </template>
                <template v-else> Submit Code </template>
              </base-button>
            </div>
            <div id="recaptcha-container"></div>
          </form>
        </div>
      </div>
      <div class="row mt-3">
        <div class="col-6">
          <a href="#" class="text-light"><small>Forgot password?</small></a>
        </div>
        <div class="col-6 text-right">
          <router-link to="/register" class="text-light"
            ><small>Create new account</small></router-link
          >
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {
  RecaptchaVerifier,
  getAuth,
  signInWithPhoneNumber,
} from "firebase/auth";

export default {
  name: "login",
  data() {
    return {
      showAlert: false,
      alertType: "",
      alertMessage: "",
      signInLoader: false,
      sendingCodeStatus: false,
      codeSent: false,
      phoneNumber: "+357",
      code: "",
      recaptchaVerifier: null,
      confirmationResult: null,
      // showModal: false
    };
  },
  mounted() {
    this.recaptchaVerifier = new RecaptchaVerifier(
      "recaptcha-container",
      {
        size: "invisible",
        callback: (response) => {
          // reCAPTCHA solved, allow signInWithPhoneNumber.
          console.log("response", response);
        },
      },
      getAuth(this.fireApp)
    );
  },
  methods: {
    resetModal() {
      this.phoneNumber = "+357";
      this.codeSent = false;
      this.clearAlert();
    },
    initAlert(type, message) {
      this.showAlert = true;
      this.alertType = type;
      this.alertMessage = message;
    },
    clearAlert() {
      this.showAlert = false;
      this.alertType = "";
      this.alertMessage = "";
    },
    getCode() {
      let self = this;
      this.clearAlert();
      this.sendingCodeStatus = true;
      console.log(this.recaptchaVerifier);
      let auth = getAuth(this.fireApp);

      signInWithPhoneNumber(auth, this.phoneNumber, this.recaptchaVerifier)
        .then((confirmationResult) => {
          // SMS sent. Prompt user to type the code from the message, then sign the
          // user in with confirmationResult.confirm(code).
          console.log("confirmationResult", confirmationResult);
          self.codeSent = true;
          self.initAlert(
            "info",
            "The 6-digit code has been sent to the mobile number given."
          );
          this.sendingCodeStatus = false;
          self.confirmationResult = confirmationResult;
          // ...
        })
        .catch((error) => {
          this.sendingCodeStatus = false;
          let message = error.message;
          if (error.code === "auth/invalid-phone-number") {
            message = "The phone number given is not valid.";
          }
          self.initAlert("danger", message);
          // Error; SMS not sent
        });
    },
    signIn() {
      let self = this;
      this.clearAlert();
      this.signInLoader = true;
      this.confirmationResult
        .confirm(this.code)
        .then((result) => {
          // User signed in successfully.
          const user = result.user;
          console.log(user);
          self.resetModal();
          // ...
        })
        .catch((error) => {
          this.signInLoader = false;
          // User couldn't sign in (bad verification code?)
          let message = error.message;
          if (error.code === "auth/invalid-verification-code") {
            message = "Verification code is invalid.";
          }
          self.initAlert("danger", message);
        });
    },
  },
};
</script>
<style></style>
