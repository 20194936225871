<template>
  <div>
    <base-header type="gradient-success" class="pb-6 pb-8 pt-5 pt-md-8">
      <!-- Card stats -->
    </base-header>

    <div class="container-fluid mt--7">
      <div class="row">
        <div class="col">
          <div class="col-xl-8 order-xl-1">
            <card shadow type="secondary">
              <template v-slot:header>
                <div class="bg-white border-0">
                  <div class="row align-items-center">
                    <div class="col-8">
                      <h3 class="mb-0">Add new System User</h3>
                    </div>
                  </div>
                </div>
              </template>

              <form>
                <div class="pl-lg-4">
                  <div class="row">
                    <div class="col-lg-6">
                      <base-input
                        alternative=""
                        label="Email"
                        placeholder="Email"
                        input-classes="form-control-alternative"
                        v-model="model.email"
                      />
                    </div>
                    <div class="col-lg-6">
                      <base-input
                        alternative=""
                        label="Password"
                        placeholder="Password"
                        input-classes="form-control-alternative"
                        v-model="model.password"
                      />
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-lg-6">
                      <base-input
                        alternative=""
                        label="First name"
                        placeholder="First name"
                        input-classes="form-control-alternative"
                        v-model="model.firstName"
                      />
                    </div>
                    <div class="col-lg-6">
                      <base-input
                        alternative=""
                        label="Last name"
                        placeholder="Last name"
                        input-classes="form-control-alternative"
                        v-model="model.lastName"
                      />
                    </div>
                    <div class="col-lg-6">
                      <base-input
                        alternative=""
                        label="Role"
                        placeholder="Role"
                        input-classes="form-control-alternative"
                        v-model="model.role"
                      />
                    </div>
                  </div>
                </div>
                <div class="col-12 text-right">
                  <base-button @click="clearForm" outline type="info"
                    >Reset</base-button
                  >
                  <submit-button @clicked="submitUser" :state="loadingProcess"
                    >Submit</submit-button
                  >
                </div>
              </form>
            </card>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import FirebaseFunctionsHelper from "../../plugins/firebaseFunctionsHelper";
export default {
  name: "newSystemUser",
  data() {
    return {
      loadingProcess: "",
      loadingMessage: "",
      model: {
        email: "",
        password: "",
        firstName: "",
        lastName: "",
        role: "",
      },
    };
  },
  methods: {
    async submitUser() {
      let self = this;
      // eslint-disable-next-line no-unreachable
      this.loadingProcess = "processing";
      FirebaseFunctionsHelper.callFunction(
        "createSystemUser",
        // eslint-disable-next-line no-undef
        this.model
      )
        .then((result) => {
          // eslint-disable-next-line no-prototype-builtins
          if (result.hasOwnProperty("message")) {
            self.loadingProcess = "failed";
            self.errorMessage = result.message;
            return;
          }
          console.log("submitUser result", result);
          this.loadingProcess = "done";
          setTimeout(function () {
            self.loadingProcess = "";
          }, 2000);
        })
        .catch((error) => {
          self.loadingProcess = "failed";
          self.errorMessage = error.message;
          console.log("submitUser error", error);
        });

      // setTimeout(() => {
      //   this.loadingProcess = "failed";
      //   this.loadingMessage = "There was an error.";
      //   //Add Tokens
      // }, 2000);
    },
    clearForm() {
      console.log("clearForm");
      this.loadingProcess = "";
      this.model = {
        email: "",
        firstName: "",
        lastName: "",
        role: "",
      };
    },
  },
};
</script>
<style></style>
